import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  SearchStudentsFilters,
  SearchStudentsStudent,
} from "@platform-app/app/core/api/models";
import { StudentService } from "@platform-app/app/core/api/services";
import { MyStudentsTabsCount } from "@platform-app/app/main/my-students/models";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";
import { finalize, of, Subject, takeUntil, tap } from "rxjs";

export interface MyStudentsStateModel {
  studentList: SearchStudentsStudent[] | null;
  totalCount: number;
  allCount: number;
  waitingToApplyCount: number;
  pendingCount: number;
  placedCount: number;
  archivedCount: number;
  currentPage: number;
  itemsPerPage: number;
  filters: SearchStudentsFilters;
  dataLoading: boolean;
  error: string | null;
  hasComplianceChecklistRequirement: boolean;
}

export class LoadStudents {
  static readonly type = "[My Students] Load Students";
  constructor() {}
}

export class PageRangeChanged {
  static readonly type = "[My Students] Page Range Changed";
  constructor(public payload: { itemsPerPage: number }) {}
}

export class AddStudentDialogRequested {
  static readonly type = "[My Students] Add Student Dialog Requested";
}

export class PageNumberChanged {
  static readonly type = "[My Students] Page Number Changed";
  constructor(public payload: { pageNumber: number }) {}
}

export class UpdateMyStudentsFilters {
  static readonly type = "[My Students] Update Filters";
  constructor(public payload: { filters: SearchStudentsFilters }) {}
}

export class ResetStudentListFilters {
  static readonly type = "[My Students] Reset Student List Pagination";
}

export class ResendStudentInvitation {
  static readonly type = "[My Students] Resend Student Invitation";
  constructor(public payload: { studentId: string }) {}
}

export class SendChecklistReminder {
  static readonly type = "[My Students] Send Checklist Reminder";
  constructor(public payload: { studentId: string }) {}
}

export class SendPreferencesReminder {
  static readonly type = "[My Students] Send Preferences Reminder";
  constructor(public payload: { studentId: string }) {}
}

const DEFAULT_MY_STUDENTS_STATE: MyStudentsStateModel = {
  studentList: null,
  totalCount: 0,
  allCount: 0,
  waitingToApplyCount: 0,
  pendingCount: 0,
  placedCount: 0,
  archivedCount: 0,
  currentPage: 1,
  itemsPerPage: 10,
  filters: {
    tab: null,
  },
  dataLoading: false,
  error: null,
  hasComplianceChecklistRequirement: false,
};

@State<MyStudentsStateModel>({
  name: "MyStudents",
  defaults: DEFAULT_MY_STUDENTS_STATE,
})
@Injectable()
export class MyStudentsState extends StateToDefaultResetter<MyStudentsStateModel> {
  private cancelPrevious$ = new Subject<void>();

  constructor(private studentService: StudentService) {
    super(DEFAULT_MY_STUDENTS_STATE);
  }

  @Selector()
  static dataLoading(state: MyStudentsStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static studentList(state: MyStudentsStateModel) {
    return state.studentList;
  }

  @Selector()
  static count(state: MyStudentsStateModel) {
    return {
      total: state.totalCount,
      all: state.allCount,
      waitingToApply: state.waitingToApplyCount,
      pending: state.pendingCount,
      placed: state.placedCount,
      archived: state.archivedCount,
    } satisfies MyStudentsTabsCount;
  }

  @Selector()
  static itemsPerPage(state: MyStudentsStateModel) {
    return state.itemsPerPage;
  }

  @Selector()
  static currentPage(state: MyStudentsStateModel) {
    return state.currentPage;
  }

  @Selector()
  static filters(state: MyStudentsStateModel): SearchStudentsFilters {
    return state.filters;
  }

  @Selector()
  static hasComplianceChecklistRequirements(state: MyStudentsStateModel) {
    return state.hasComplianceChecklistRequirement;
  }

  @Action(LoadStudents)
  loadPrograms(ctx: StateContext<MyStudentsStateModel>) {
    return this.getStudents(ctx);
  }

  @Action(PageNumberChanged)
  pageNumberChanged(
    ctx: StateContext<MyStudentsStateModel>,
    action: PageNumberChanged,
  ) {
    ctx.patchState({
      currentPage: action.payload.pageNumber,
    });

    return this.getStudents(ctx);
  }

  @Action(PageRangeChanged)
  pageRangeChanged(
    ctx: StateContext<MyStudentsStateModel>,
    action: PageRangeChanged,
  ) {
    ctx.patchState({
      itemsPerPage: action.payload.itemsPerPage,
      currentPage: 1,
    });

    return this.getStudents(ctx);
  }

  @Action(UpdateMyStudentsFilters)
  updateFilters(
    ctx: StateContext<MyStudentsStateModel>,
    action: UpdateMyStudentsFilters,
  ) {
    const stateSnapshot = ctx.getState();

    const {
      cohortId = stateSnapshot.filters?.cohortId,
      disciplineIds = stateSnapshot.filters?.disciplineIds,
      tab = stateSnapshot.filters?.tab,
    } = action.payload.filters;

    ctx.patchState({
      filters: {
        cohortId,
        disciplineIds,
        tab,
      },
      currentPage: 1,
    });

    return this.getStudents(ctx);
  }

  @Action(ResetStudentListFilters)
  resetStudentList(ctx: StateContext<MyStudentsStateModel>) {
    const stateSnapshot = ctx.getState();
    ctx.patchState({
      currentPage: 1,
      itemsPerPage: 10,
      studentList: null,
      totalCount: 0,
      filters: {
        cohortId: stateSnapshot.filters.cohortId,
        disciplineIds: stateSnapshot.filters.disciplineIds,
        tab: null,
      },
    });
  }

  @Action(ResendStudentInvitation)
  resendStudentInvitation(
    _: StateContext<MyStudentsStateModel>,
    action: ResendStudentInvitation,
  ) {
    return this.studentService.resendInvitation({
      id: action.payload.studentId,
    });
  }

  @Action(SendChecklistReminder)
  sendChecklistReminder(
    _: StateContext<MyStudentsStateModel>,
    action: SendChecklistReminder,
  ) {
    return this.studentService.sendStudentComplianceChecklistReminder({
      id: action.payload.studentId,
    });
  }

  @Action(SendPreferencesReminder)
  sendPreferencesReminder(
    _: StateContext<MyStudentsStateModel>,
    action: SendPreferencesReminder,
  ) {
    return this.studentService.sendStudentPreferencesReminder({
      id: action.payload.studentId,
    });
  }

  private getStudents(ctx: StateContext<MyStudentsStateModel>) {
    const stateSnapshot = ctx.getState();
    this.cancelPrevious$.next();

    if (!stateSnapshot.studentList) {
      ctx.patchState({
        dataLoading: true,
      });
    }

    return this.studentService
      .studentPost({
        body: {
          pagination: {
            page: stateSnapshot.currentPage,
            itemsPerPage: stateSnapshot.itemsPerPage,
          },
          filters: stateSnapshot.filters,
        },
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            studentList: response.students,
            totalCount: response.totalCount,
            allCount: response.allCount,
            waitingToApplyCount: response.waitingToApplyCount,
            pendingCount: response.pendingCount,
            placedCount: response.placedCount,
            archivedCount: response.archivedCount,
            hasComplianceChecklistRequirement:
              response.hasComplianceChecklistRequirement,
          });
          return of(response);
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() =>
          ctx.patchState({
            dataLoading: false,
          }),
        ),
      );
  }
}
