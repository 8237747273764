import { SaveOpportunityRequirementsRequest } from "@platform-app/app/core/api/models";
import { OpportunityOtherDocumentUploadInfo } from "@platform-app/app/main/my-opportunities/models";

export class GetDeadlines {
  static readonly type = "[Externship Opportunity] Get Deadlines";
}

export class LoadComplianceChecklistRequirements {
  static readonly type =
    "[Externship Opportunity] Load Compliance Checklist Requirements";
}

export class UpdateOtherDocuments {
  static readonly type = "[Externship Opportunity] Update Other Documents";
  constructor(public payload: OpportunityOtherDocumentUploadInfo[]) {}
}

export class UploadOtherDocuments {
  static readonly type = "[Externship Opportunity] Upload Other Documents";
  constructor(
    public payload: {
      documents: OpportunityOtherDocumentUploadInfo[];
    },
  ) {}
}

export class SaveOpportunityRequirements {
  static readonly type =
    "[Externship Opportunity] Save Opportunity Requirements";
  constructor(public payload: { body: SaveOpportunityRequirementsRequest }) {}
}

export class SaveComplianceChecklistRequirements {
  static readonly type =
    "[Externship Opportunity] Save Compliance Checklist Requirements";
  constructor(public payload: { requirementIds: string[] }) {}
}

export class SetLoading {
  static readonly type = "[Externship Opportunity] Set Loading";
  constructor(public payload: { isLoading: boolean }) {}
}
