import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { GetOrganizationInfoResponse } from "@platform-app/app/core/api/models/get-organization-info-response";
import { forkJoin, tap } from "rxjs";
import {
  OrganizationService,
  SchoolService,
} from "@platform-app/app/core/api/services";
import { GetOrganizationStaffStaff } from "@platform-app/app/core/api/models/get-organization-staff-staff";
import { GetProgramsProgram } from "@platform-app/app/core/api/models/get-programs-program";
import { GetOrganizationSubteamsTeam } from "@platform-app/app/core/api/models";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";

export interface SchoolProfileStateModel {
  schoolOrganization: GetOrganizationInfoResponse | null;
  staff: GetOrganizationStaffStaff[] | null;
  teams: GetOrganizationSubteamsTeam[] | null;
  programs: GetProgramsProgram[];
}

export class LoadSchoolData {
  static readonly type = "[School Profile] Get School Details";
  constructor(public payload: { id: string }) {}
}

const DEFAULT_SCHOOL_PROFILE_STATE: SchoolProfileStateModel = {
  schoolOrganization: null,
  staff: null,
  teams: null,
  programs: [],
};

@State<SchoolProfileStateModel>({
  name: "SchoolProfile",
  defaults: {
    schoolOrganization: null,
    staff: null,
    teams: null,
    programs: [],
  },
})
@Injectable()
export class SchoolProfileState extends StateToDefaultResetter<SchoolProfileStateModel> {
  constructor(
    private readonly organizationService: OrganizationService,
    private schoolService: SchoolService,
  ) {
    super(DEFAULT_SCHOOL_PROFILE_STATE);
  }

  @Selector()
  static schoolOrganization(state: SchoolProfileStateModel) {
    return state.schoolOrganization;
  }

  @Selector()
  static schoolStaff(state: SchoolProfileStateModel) {
    return state.staff;
  }

  @Selector()
  static teams(state: SchoolProfileStateModel) {
    return state.teams;
  }

  @Selector()
  static programs(state: SchoolProfileStateModel) {
    return state.programs;
  }

  @Action(LoadSchoolData)
  getClinic(
    ctx: StateContext<SchoolProfileStateModel>,
    action: LoadSchoolData,
  ) {
    const organizationId = action.payload.id;

    return forkJoin([
      this.organizationService.organizationOrganizationIdGet({
        organizationId,
      }),
      this.organizationService.organizationOrganizationIdStaffGet({
        organizationId,
      }),
      this.organizationService.getOrganizationSubteams({ organizationId }),
      this.schoolService.getPrograms({
        schoolId: organizationId,
        publicOnly: true,
      }),
    ]).pipe(
      tap(([organizationData, staff, teams, programsResponse]) => {
        ctx.patchState({
          schoolOrganization: organizationData,
          staff: staff.staff,
          teams: teams.teams,
          programs: programsResponse.programs,
        });
      }),
    );
  }
}
