import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  GetApplicationDeadlinesApplicationDeadline,
  GetOpportunityComplianceChecklistRequirement,
  UploadAgreementDocumentsDocumentInfo,
} from "@platform-app/app/core/api/models";
import { OpportunityService } from "@platform-app/app/core/api/services";
import { tap, catchError, finalize } from "rxjs";
import { OpportunityOtherDocumentUploadInfo } from "@platform-app/app/main/my-opportunities/models";
import {
  MyOpportunityState,
  SetCurrentOpportunity,
} from "@platform-app/app/main/my-opportunities/my-opportunities.state";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";
import {
  SetLoading,
  GetDeadlines,
  UpdateOtherDocuments,
  UploadOtherDocuments,
  SaveOpportunityRequirements,
  SaveComplianceChecklistRequirements,
  LoadComplianceChecklistRequirements,
} from "@platform-app/app/main/my-opportunities/my-opportunities-setup/externship-opportunity/externship-opportunity.actions";

export interface ExternshipOpportunityStateModel {
  deadlines: GetApplicationDeadlinesApplicationDeadline[] | null;
  otherRequirementDocuments: OpportunityOtherDocumentUploadInfo[] | null;
  complianceChecklistRequirements:
    | GetOpportunityComplianceChecklistRequirement[]
    | null;
  dataLoading: boolean;
  error: string | null;
}

const DEFAULT_EXTERN_OPPORTUNITY_STATE: ExternshipOpportunityStateModel = {
  deadlines: null,
  otherRequirementDocuments: null,
  complianceChecklistRequirements: null,
  dataLoading: false,
  error: null,
};

@State<ExternshipOpportunityStateModel>({
  name: "ExternshipOpportunity",
  defaults: DEFAULT_EXTERN_OPPORTUNITY_STATE,
})
@Injectable()
export class ExternshipOpportunityState extends StateToDefaultResetter<ExternshipOpportunityStateModel> {
  constructor(
    private opportunityService: OpportunityService,
    private store: Store,
  ) {
    super(DEFAULT_EXTERN_OPPORTUNITY_STATE);
  }

  @Selector()
  static otherRequirementDocuments(state: ExternshipOpportunityStateModel) {
    return state.otherRequirementDocuments;
  }

  @Selector()
  static complianceChecklistRequirements(
    state: ExternshipOpportunityStateModel,
  ) {
    return state.complianceChecklistRequirements;
  }

  @Selector()
  static deadlines(state: ExternshipOpportunityStateModel) {
    return state.deadlines;
  }

  @Selector()
  static dataLoading(state: ExternshipOpportunityStateModel) {
    return state.dataLoading;
  }

  @Action(SetLoading)
  setLoading(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: SetLoading,
  ) {
    ctx.patchState({
      dataLoading: action.payload.isLoading,
    });
  }

  @Action(GetDeadlines)
  getDeadlines(ctx: StateContext<ExternshipOpportunityStateModel>) {
    const { deadlines } = ctx.getState();

    if (deadlines?.length) {
      return deadlines;
    }

    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService.opportunityApplicationDeadlinesGet().pipe(
      tap((response) => {
        ctx.patchState({
          deadlines: response,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          error: error,
        });
        throw error;
      }),
      finalize(() => {
        ctx.patchState({
          dataLoading: false,
        });
      }),
    );
  }

  @Action(UpdateOtherDocuments)
  updateOtherDocuments(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: UpdateOtherDocuments,
  ) {
    ctx.patchState({
      otherRequirementDocuments: action.payload,
    });
  }

  @Action(UploadOtherDocuments)
  uploadOtherDocuments(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: UploadOtherDocuments,
  ) {
    const opportunityId = this.store.selectSnapshot(
      MyOpportunityState.opportunityId,
    )!;

    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService
      .opportunityOpportunityIdAgreementDocumentsPost({
        opportunityId,
        body: {
          ...mapOtherDocumentsPayload(action.payload.documents),
        },
      })
      .pipe(
        tap(() => {
          ctx.patchState({
            otherRequirementDocuments: null,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            error: error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(SaveOpportunityRequirements)
  saveOpportunityRequirements(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: SaveOpportunityRequirements,
  ) {
    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService
      .opportunityRequirementsPut({
        body: action.payload.body,
      })
      .pipe(
        tap((response) => {
          this.store.dispatch(
            new SetCurrentOpportunity({ id: response.opportunityId }),
          );
        }),
        catchError((error) => {
          ctx.patchState({
            error: error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(LoadComplianceChecklistRequirements)
  loadComplianceChecklistRequirements(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    _action: LoadComplianceChecklistRequirements,
  ) {
    const opportunityId = this.store.selectSnapshot(
      MyOpportunityState.opportunityId,
    )!;

    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService
      .getOpportunityComplianceChecklist({
        opportunityId,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            complianceChecklistRequirements: response.requirements,
          });
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(SaveComplianceChecklistRequirements)
  saveComplianceChecklistRequirements(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: SaveComplianceChecklistRequirements,
  ) {
    const opportunityId = this.store.selectSnapshot(
      MyOpportunityState.opportunityId,
    )!;

    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService
      .saveComplianceChecklist({
        opportunityId,
        body: { requirementIds: action.payload.requirementIds },
      })
      .pipe(
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }
}

const mapOtherDocumentsPayload = (
  documents: OpportunityOtherDocumentUploadInfo[],
): {
  DocumentsInfo: {
    documentInfos: UploadAgreementDocumentsDocumentInfo[] | null;
  };
  Files: Blob[];
} => {
  const documentInfos: UploadAgreementDocumentsDocumentInfo[] = [];
  const files: Blob[] = [];

  documents.forEach((doc) => {
    documentInfos.push(doc.documentInfo!);
    files.push(doc.file!);
  });

  return {
    DocumentsInfo: { documentInfos },
    Files: files,
  };
};
