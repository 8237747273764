import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, Subject, finalize, of, switchMap, takeUntil, tap } from "rxjs";
import {
  PublishedOpportunityService,
  StudentService,
} from "@platform-app/app/core/api/services";
import {
  GetOpportunityAvailabilityForStudentInterestResponse,
  GetPublishedOpportunityOpportunityDetails,
  GetPublishedOpportunitySeatsAvailabilityCalculationType,
  GetStudentsAvailableForMatchingStudent,
  SearchPublishedOpportunitiesFilters,
  SearchPublishedOpportunitiesOpportunity,
  SmartMatchOpportunity,
} from "@platform-app/app/core/api/models";
import { DateUtility } from "../shared/utilities/date.utility";
import { AnalyticsService } from "@platform-app/app/core/analytics/analytics.service";
import {
  OpportunityDetailsViewed,
  OpportunityListingViewed,
  SmartMatchListingViewed,
} from "@platform-app/app/core/analytics/events";
import {
  EnsureDisciplinesLoaded,
  StaticDataState,
} from "@platform-app/app/main/shared/state/static-data.state";
import { StudentFilterUtility } from "@platform-app/app/main/shared/utilities/student-filter.utility";
import { ScoreThreshold } from "@platform-app/app/main/shared/components/smart-match/models";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";

export interface OpportunitiesListingStateModel {
  viewedOpportunitiesCount: number;
  opportunityList:
    | SearchPublishedOpportunitiesOpportunity[]
    | SmartMatchOpportunity[]
    | null;
  opportunityTotalAmount: number;
  selectedOpportunity: GetPublishedOpportunityOpportunityDetails | null;
  selectedOpportunityId: string | null;
  opportunityAvailabilityForStudent: GetOpportunityAvailabilityForStudentInterestResponse | null;
  currentPage: number;
  itemsPerPage: number;
  filters: SearchPublishedOpportunitiesFilters;
  isFiltersModified: boolean;
  filterPlaceId: string | null;
  searchText: string | null;
  error: string | null;
  opportunityListLoading: boolean;
  opportunityDetailsLoading: boolean;
  smartMatchActive: boolean;
  smartMatchStudentId: string | null;
  smartMatchStudents: GetStudentsAvailableForMatchingStudent[] | null;
  smartMatchSearchText: string | null;
  smartMatchStudentSearch: string | null;
  smartMatchDisplayedStudents: GetStudentsAvailableForMatchingStudent[] | null;
}

export class InitOpportunities {
  static readonly type = "[Opportunities Listing] Load Opportunities";
  constructor(
    public payload: { filters: SearchPublishedOpportunitiesFilters },
  ) {}
}

export class LoadSelectedOpportunityDetails {
  static readonly type = "[Opportunities Listing] Load Opportunity Details";
  constructor(public payload: { opportunityId: string }) {}
}

export class LoadOpportunityAvailabilityForStudent {
  static readonly type =
    "[Opportunities Listing] Load Opportunity Availability For Student";
  constructor(public payload: { opportunityId: string }) {}
}

export class UpdateOpportunityAvailabilityForStudent {
  static readonly type =
    "[Opportunities Listing] Update Opportunity Availability For Student";
  constructor(
    public payload: { id: string; body: { opportunityId: string } },
  ) {}
}

export class PageRangeChanged {
  static readonly type = "[Opportunities Listing] Page Range Changed";
  constructor(public payload: { itemsPerPage: number }) {}
}

export class PageNumberChanged {
  static readonly type = "[Opportunities Listing] Page Number Changed";
  constructor(public payload: { pageNumber: number }) {}
}

export class ResetOpportunityListing {
  static readonly type =
    "[Opportunities Listing] Reset Opportunity Listing Pagination";
}

export class UpdateOpportunityListingFilters {
  static readonly type =
    "[Opportunities Listing] Update Opportunity Listing Filters";
  constructor(
    public payload: {
      filters: {
        searchText?: string | null;
        filterPlaceId?: string | null;
      } & SearchPublishedOpportunitiesFilters;
    },
  ) {}
}

export class ResetSelectedOpportunity {
  static readonly type = "[Opportunities Listing] Reset Selected Opportunity";
}

export class SetSmartMatchActive {
  static readonly type = "[Opportunities Listing] Set Smart Match Active";
  constructor(
    public payload: {
      active: boolean;
    },
  ) {}
}

export class SmartMatchStudentSearch {
  static readonly type = "[Opportunities Listing] SmartMatch Student Search";
  constructor(
    public payload: {
      search: string;
    },
  ) {}
}

export class SmartMatchStudent {
  static readonly type = "[Opportunities Listing] SmartMatch Student";
  constructor(
    public payload: {
      studentId: string;
    },
  ) {}
}

const DEFAULT_OPPORTUNITIES_LISTING_STATE: OpportunitiesListingStateModel = {
  viewedOpportunitiesCount: 0,
  opportunityList: null,
  opportunityTotalAmount: 0,
  selectedOpportunity: null,
  selectedOpportunityId: null,
  opportunityAvailabilityForStudent: null,
  currentPage: 1,
  itemsPerPage: 10,
  filters: {
    opportunityTypes: null,
    disciplinesIds: null,
    startDate: null,
    endDate: null,
    locationFilterText: null,
    locationFilterType: null,
    location: null,
    radius: null,
  },
  isFiltersModified: false,
  filterPlaceId: null,
  searchText: null,
  error: null,
  opportunityListLoading: false,
  opportunityDetailsLoading: false,
  smartMatchActive: false,
  smartMatchStudentId: null,
  smartMatchStudents: null,
  smartMatchSearchText: null,
  smartMatchStudentSearch: null,
  smartMatchDisplayedStudents: null,
};

@State<OpportunitiesListingStateModel>({
  name: "OpportunitiesListing",
  defaults: DEFAULT_OPPORTUNITIES_LISTING_STATE,
})
@Injectable()
export class OpportunitiesListingState extends StateToDefaultResetter<OpportunitiesListingStateModel> {
  cancelPrevious$ = new Subject<void>();
  private readonly minApplicableSearchLength = 3;

  constructor(
    private readonly publishedOpportunityService: PublishedOpportunityService,
    private readonly studentService: StudentService,
    private readonly analyticsService: AnalyticsService,
    private readonly store: Store,
  ) {
    super(DEFAULT_OPPORTUNITIES_LISTING_STATE);
  }

  @Selector()
  static opportunityList(state: OpportunitiesListingStateModel) {
    return state.opportunityList;
  }

  @Selector()
  static selectedOpportunity(state: OpportunitiesListingStateModel) {
    return state.selectedOpportunity;
  }

  @Selector()
  static smartMatchDisplayedStudents(state: OpportunitiesListingStateModel) {
    return state.smartMatchDisplayedStudents;
  }

  @Selector()
  static selectedOpportunityId(state: OpportunitiesListingStateModel) {
    return state.selectedOpportunityId;
  }

  @Selector()
  static opportunityTotalAmount(state: OpportunitiesListingStateModel) {
    return state.opportunityTotalAmount;
  }

  @Selector()
  static itemsPerPage(state: OpportunitiesListingStateModel) {
    return state.itemsPerPage;
  }

  @Selector()
  static smartMatchSearchText(state: OpportunitiesListingStateModel) {
    return state.smartMatchStudentSearch;
  }

  @Selector()
  static currentPage(state: OpportunitiesListingStateModel) {
    return state.currentPage;
  }

  @Selector()
  static opportunityListLoading(state: OpportunitiesListingStateModel) {
    return state.opportunityListLoading;
  }

  @Selector()
  static opportunityDetailsLoading(state: OpportunitiesListingStateModel) {
    return state.opportunityDetailsLoading;
  }

  @Selector()
  static opportunityAvailabilityForStudent(
    state: OpportunitiesListingStateModel,
  ) {
    return state.opportunityAvailabilityForStudent;
  }

  @Selector()
  static filters(state: OpportunitiesListingStateModel) {
    return state.filters;
  }

  @Selector()
  static filterPlaceId(state: OpportunitiesListingStateModel) {
    return state.filterPlaceId;
  }

  @Selector()
  static isFiltersModified(state: OpportunitiesListingStateModel) {
    return state.isFiltersModified;
  }

  @Selector()
  static searchText(state: OpportunitiesListingStateModel) {
    return state.smartMatchActive
      ? state.smartMatchSearchText
      : state.searchText;
  }

  @Selector()
  static smartMatchActive(state: OpportunitiesListingStateModel) {
    return state.smartMatchActive;
  }

  @Selector()
  static smartMatchStudentId(state: OpportunitiesListingStateModel) {
    return state.smartMatchStudentId;
  }

  @Selector()
  static smartMatchStudents(state: OpportunitiesListingStateModel) {
    return state.smartMatchStudents;
  }

  @Selector()
  static smartMatchStudentEmptyPreferences(
    state: OpportunitiesListingStateModel,
  ) {
    return !state.smartMatchStudents?.find(
      (s) => s.id === state.smartMatchStudentId,
    )?.preferencesFilled;
  }

  @Action(InitOpportunities)
  loadOpportunities(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: InitOpportunities,
  ) {
    const state = ctx.getState();

    return state.smartMatchActive
      ? this.loadSmartMatch(ctx)
      : this.getOpportunities(ctx, action.payload.filters);
  }

  @Action(LoadSelectedOpportunityDetails)
  loadSelectedOpportunityDetails(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: LoadSelectedOpportunityDetails,
  ) {
    this.trackOpportunityDetailsViewed(action.payload.opportunityId);

    return this.getSelectedOpportunityDetails(
      ctx,
      action.payload.opportunityId,
    );
  }

  @Action(LoadOpportunityAvailabilityForStudent)
  loadOpportunityAvailabilityForStudent(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: LoadOpportunityAvailabilityForStudent,
  ) {
    ctx.patchState({
      opportunityDetailsLoading: true,
    });

    return this.publishedOpportunityService
      .getOpportunityAvailabilityForStudentInterest({
        opportunityId: action.payload.opportunityId,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            opportunityAvailabilityForStudent: response,
          });
          return of(response);
        }),
        finalize(() =>
          ctx.patchState({
            opportunityDetailsLoading: false,
          }),
        ),
      );
  }

  @Action(UpdateOpportunityAvailabilityForStudent)
  updateOpportunityAvailabilityForStudent(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: UpdateOpportunityAvailabilityForStudent,
  ) {
    ctx.patchState({
      opportunityDetailsLoading: true,
    });

    return this.studentService
      .addStudentOpportunityInterests({
        id: action.payload.id,
        body: action.payload.body,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            opportunityAvailabilityForStudent: {
              isAvailable: false,
              lastSentEmailDate: new Date().toDateString(),
            },
          });
          return of(response);
        }),
        finalize(() =>
          ctx.patchState({
            opportunityDetailsLoading: false,
          }),
        ),
      );
  }

  @Action(PageRangeChanged)
  pageRangeChanged(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: PageRangeChanged,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      itemsPerPage: action.payload.itemsPerPage,
      currentPage: 1,
    });

    return state.smartMatchActive
      ? this.loadSmartMatch(ctx)
      : this.getOpportunities(ctx);
  }

  @Action(PageNumberChanged)
  pageNumberChanged(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: PageNumberChanged,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      currentPage: action.payload.pageNumber,
    });

    return state.smartMatchActive
      ? this.loadSmartMatch(ctx)
      : this.getOpportunities(ctx);
  }

  @Action(ResetOpportunityListing)
  resetOpportunityList(ctx: StateContext<OpportunitiesListingStateModel>) {
    const isSmartMatch = ctx.getState().smartMatchActive;

    isSmartMatch
      ? this.trackSmartMatchListingViewed(ctx)
      : this.trackOpportunityListingViewed(ctx);

    ctx.patchState({
      viewedOpportunitiesCount: 0,
      currentPage: 1,
      itemsPerPage: 10,
      opportunityList: null,
      opportunityTotalAmount: 0,
      selectedOpportunity: null,
      selectedOpportunityId: null,
      smartMatchStudents: null,
      opportunityAvailabilityForStudent: null,
    });
  }

  @Action(UpdateOpportunityListingFilters)
  updateFilters(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: UpdateOpportunityListingFilters,
  ) {
    this.trackOpportunityListingViewed(ctx);

    const stateSnapshot = ctx.getState();

    const {
      disciplinesIds = stateSnapshot.filters?.disciplinesIds,
      opportunityTypes = stateSnapshot.filters?.opportunityTypes,
      startDate = stateSnapshot.filters?.startDate,
      endDate = stateSnapshot.filters?.endDate,
      locationFilterText = stateSnapshot.filters?.locationFilterText,
      locationFilterType = stateSnapshot.filters?.locationFilterType,
      location = stateSnapshot.filters?.location,
      radius = stateSnapshot.filters?.radius,
      searchText = stateSnapshot.searchText,
      filterPlaceId = stateSnapshot.filterPlaceId,
    } = action.payload.filters;

    ctx.patchState({
      viewedOpportunitiesCount: 0,
      filters: {
        disciplinesIds,
        locationFilterText,
        locationFilterType,
        location,
        radius,
        opportunityTypes,
        startDate,
        endDate,
      },
      isFiltersModified: true,
      filterPlaceId,
      currentPage: 1,
    });

    ctx.patchState(
      stateSnapshot.smartMatchActive
        ? { smartMatchSearchText: searchText }
        : { searchText },
    );

    return stateSnapshot.smartMatchActive
      ? this.loadSmartMatch(ctx)
      : this.getOpportunities(ctx);
  }

  @Action(ResetSelectedOpportunity)
  resetSelectedOpportunity(ctx: StateContext<OpportunitiesListingStateModel>) {
    ctx.patchState({
      selectedOpportunity: null,
      selectedOpportunityId: null,
    });
  }

  @Action(SetSmartMatchActive)
  setSmartMatchActive(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: SetSmartMatchActive,
  ) {
    const snapshot = ctx.getState();
    if (snapshot.smartMatchActive && !action.payload.active) {
      this.trackSmartMatchListingViewed(ctx);
      ctx.patchState({ viewedOpportunitiesCount: 0 });
    }
    if (!snapshot.smartMatchActive && action.payload.active) {
      this.trackOpportunityListingViewed(ctx);
      ctx.patchState({ viewedOpportunitiesCount: 0 });
    }

    ctx.patchState({
      currentPage: 1,
    });

    const request = action.payload.active
      ? this.loadSmartMatch(ctx)
      : this.getOpportunities(ctx);

    return request.pipe(
      tap(() =>
        ctx.patchState({
          smartMatchActive: action.payload.active,
        }),
      ),
    );
  }

  @Action(SmartMatchStudentSearch)
  setSmartMatchStudentSearch(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: SmartMatchStudentSearch,
  ) {
    const snapshot = ctx.getState();
    const smartMatchStudentSearch = action.payload.search.trim();

    ctx.patchState({ smartMatchStudentSearch });

    if (action.payload.search.length < this.minApplicableSearchLength) {
      ctx.patchState({
        smartMatchDisplayedStudents: snapshot.smartMatchStudents,
      });
    } else {
      const filteredStudents = StudentFilterUtility.filterStudents(
        smartMatchStudentSearch,
        snapshot.smartMatchStudents || [],
      );
      ctx.patchState({ smartMatchDisplayedStudents: filteredStudents });
    }
  }

  @Action(SmartMatchStudent)
  smartMatchStudent(
    ctx: StateContext<OpportunitiesListingStateModel>,
    action: SmartMatchStudent,
  ) {
    const snapshot = ctx.getState();
    if (snapshot.smartMatchStudentId === action.payload.studentId) return EMPTY;

    this.trackSmartMatchListingViewed(ctx);

    ctx.patchState({
      smartMatchStudentId: action.payload.studentId,
      smartMatchActive: true,
      currentPage: 1,
      viewedOpportunitiesCount: 0,
    });

    if (!snapshot.smartMatchStudents)
      return this.getStudentsForSmartMatch(ctx).pipe(
        switchMap(() =>
          this.getSmartMatchOpportunities(action.payload.studentId, ctx),
        ),
      );

    return this.getSmartMatchOpportunities(action.payload.studentId, ctx);
  }

  private getOpportunities(
    ctx: StateContext<OpportunitiesListingStateModel>,
    filters?: SearchPublishedOpportunitiesFilters,
  ) {
    const stateSnapshot = ctx.getState();
    this.cancelPrevious$.next();

    if (!stateSnapshot.opportunityList) {
      ctx.patchState({
        opportunityListLoading: true,
      });
    }

    return this.publishedOpportunityService
      .opportunitiesPublishedSearchPost({
        body: {
          pagination: {
            page: stateSnapshot.currentPage,
            itemsPerPage: stateSnapshot.itemsPerPage,
          },
          filters: filters || stateSnapshot.filters,
          searchText: stateSnapshot.searchText,
        },
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            opportunityList: response.opportunities,
            opportunityTotalAmount: response.totalAmount,
          });
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() => ctx.patchState({ opportunityListLoading: false })),
      );
  }

  private getSelectedOpportunityDetails(
    ctx: StateContext<OpportunitiesListingStateModel>,
    opportunityId: string,
  ) {
    const stateSnapshot = ctx.getState();

    if (
      !stateSnapshot.selectedOpportunity ||
      !stateSnapshot.selectedOpportunityId
    ) {
      ctx.patchState({
        opportunityDetailsLoading: true,
      });
    }

    ctx.patchState({
      selectedOpportunityId: opportunityId,
      viewedOpportunitiesCount: stateSnapshot.viewedOpportunitiesCount + 1,
    });

    return this.publishedOpportunityService
      .getPublishedOpportunity({
        opportunityId: opportunityId,
        body: {
          seatsCalculationType: stateSnapshot.smartMatchActive
            ? GetPublishedOpportunitySeatsAvailabilityCalculationType.StudentPreference
            : GetPublishedOpportunitySeatsAvailabilityCalculationType.ProvideDates,
          studentId: stateSnapshot.smartMatchStudentId,
          seatsAvailabilityStartDate:
            stateSnapshot.filters.startDate ??
            DateUtility.convertToDateOnlyString(new Date()),
          seatsAvailabilityEndDate:
            stateSnapshot.filters.endDate ??
            DateUtility.convertToDateOnlyString(new Date()),
        },
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            selectedOpportunity: response,
          });
          return of(response);
        }),
        finalize(() =>
          ctx.patchState({
            opportunityDetailsLoading: false,
          }),
        ),
      );
  }

  private loadSmartMatch(ctx: StateContext<OpportunitiesListingStateModel>) {
    return this.getStudentsForSmartMatch(ctx).pipe(
      switchMap(() => {
        const currentStudentId = ctx.getState().smartMatchStudentId;

        if (currentStudentId)
          return this.getSmartMatchOpportunities(currentStudentId, ctx);

        ctx.patchState({
          opportunityList: [],
          opportunityTotalAmount: 0,
        });

        return EMPTY;
      }),
    );
  }

  private getStudentsForSmartMatch(
    ctx: StateContext<OpportunitiesListingStateModel>,
  ) {
    let currentStudentId = ctx.getState().smartMatchStudentId;

    return this.studentService.studentAvailableForMatchingGet().pipe(
      tap((students) => {
        if (!students.find((student) => student.id === currentStudentId))
          currentStudentId = students.length ? students[0].id : null;

        ctx.patchState({
          smartMatchStudents: students,
          smartMatchDisplayedStudents: students,
          smartMatchStudentId: currentStudentId,
        });
      }),
    );
  }

  private getSmartMatchOpportunities(
    studentId: string,
    ctx: StateContext<OpportunitiesListingStateModel>,
  ) {
    const stateSnapshot = ctx.getState();
    this.cancelPrevious$.next();

    if (!stateSnapshot.opportunityList) {
      ctx.patchState({
        opportunityListLoading: true,
      });
    }

    return this.publishedOpportunityService
      .opportunitiesPublishedSmartMatchPost({
        body: {
          pagination: {
            page: stateSnapshot.currentPage,
            itemsPerPage: stateSnapshot.itemsPerPage,
          },
          studentId: studentId,
          searchText: stateSnapshot.smartMatchSearchText,
        },
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            opportunityList: response.opportunities,
            opportunityTotalAmount: response.totalAmount,
          });
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() => ctx.patchState({ opportunityListLoading: false })),
      );
  }

  private trackOpportunityListingViewed(
    ctx: StateContext<OpportunitiesListingStateModel>,
  ) {
    const snapshot = ctx.getState();

    ctx.dispatch(new EnsureDisciplinesLoaded()).subscribe(() => {
      const disciplines = this.store.selectSnapshot(
        StaticDataState.disciplines,
      );

      this.analyticsService.track(
        new OpportunityListingViewed({
          opportunityDetailsViewsNumber: snapshot.viewedOpportunitiesCount,
          appliedLocationFilter: snapshot.filters.locationFilterText,
          appliedExperienceTypeFilter: snapshot.filters.opportunityTypes || [],
          appliedDisciplineFilter:
            snapshot.filters.disciplinesIds?.map(
              (id) => disciplines.find((d) => d.id === id)!.name,
            ) || [],
          appliedSearchBar: snapshot.searchText,
          appliedStartDateFilter: [
            snapshot.filters.startDate!,
            snapshot.filters.endDate!,
          ]
            .filter((d) => d)
            .map((d) => new Date(d))
            .map((d) => DateUtility.convertToDateOnlyString(d)!),
        }),
      );
    });
  }

  private trackSmartMatchListingViewed(
    ctx: StateContext<OpportunitiesListingStateModel>,
  ) {
    const snapshot = ctx.getState();

    this.analyticsService.track(
      new SmartMatchListingViewed({
        opportunityDetailsViewsNumber: snapshot.viewedOpportunitiesCount,
        numberOfStudents: snapshot.smartMatchStudents?.length || 0,
        smartMatchScoreHigh: (
          snapshot.opportunityList as SmartMatchOpportunity[]
        )?.some((o) => o.match.score >= ScoreThreshold.High),
        appliedSearchStudentBar: snapshot.smartMatchStudentSearch,
      }),
    );

    ctx.patchState({ viewedOpportunitiesCount: 0 });
  }

  private trackOpportunityDetailsViewed(opportunityId: string) {
    this.analyticsService.track(
      new OpportunityDetailsViewed({
        opportunityId,
      }),
    );
  }
}
