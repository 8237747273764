import {
  AgreementPartySide,
  DynamicField,
  MergeTag,
  MergeTagModel,
  SignatoryPlaceholderReplaceModel,
} from "@platform-app/app/agreement-builder/shared/models";
import { UserGroup } from "@platform-app/app/core/api/models";

export const MERGE_TAGS: MergeTagModel[] = [
  {
    title: "Healthcare Organization Name",
    value: MergeTag.ClinicName,
    fieldId: DynamicField.CurrentOrganizationName,
  },
  {
    title: "Educational Institution Name",
    value: MergeTag.SchoolName,
    fieldId: DynamicField.CounterpartyOrganizationName,
  },
  {
    title: "Agreement Start Date",
    value: MergeTag.StartDate,
    fieldId: DynamicField.StartDate,
  },
  {
    title: "Agreement End Date",
    value: MergeTag.EndDate,
    fieldId: DynamicField.EndDate,
  },
  {
    title: "Disciplines",
    value: MergeTag.Disciplines,
    fieldId: DynamicField.Disciplines,
  },
];

export const EDITOR_CONTENT_FILE_TYPE = "text/html";

const createSignatoryFields = (
  suffix: string,
): SignatoryPlaceholderReplaceModel[] => [
  {
    fieldId: "fullName",
    placeholderId: `SIGN_FULLNAME_${suffix}`,
  },
  {
    fieldId: "title",
    placeholderId: `SIGN_TITLE_${suffix}`,
  },
  {
    fieldId: "address",
    placeholderId: `SIGN_ADDRESS_${suffix}`,
  },
  {
    fieldId: "date",
    placeholderId: `SIGN_DATE_${suffix}`,
  },
  {
    fieldId: "signature",
    placeholderId: `SIGN_SIGNATURE_${suffix}`,
  },
];

export const AGREEMENT_SIGNATORY_PLACEHOLDER_FIELDS_MAP: Record<
  AgreementPartySide,
  SignatoryPlaceholderReplaceModel[]
> = {
  [UserGroup.SchoolUser]: createSignatoryFields("SCHOOL"),
  [UserGroup.ClinicUser]: createSignatoryFields("CLINIC"),
};
