import {
  AGREEMENT_BUILDER_SELECTORS,
  AGREEMENT_BUILDER_SIGNATORY_PLACEHOLDER_ID,
} from "@platform-app/app/agreement-builder/agreement-editor/agreement-editor.constants";
import { EDITOR_CONTENT_FILE_TYPE } from "@platform-app/app/agreement-builder/shared/constants";
import {
  DynamicField,
  DynamicFieldModel,
  MergeTag,
} from "@platform-app/app/agreement-builder/shared/models";
import { US_DatePipe } from "@platform-app/app/main/shared/pipes/us-date.pipe";

export class EditorDynamicFieldsUtility {
  static getStringFromFieldValue(
    field: DynamicFieldModel,
  ): string | null | undefined {
    if (field.value === null) return null;

    const usDatePipe = new US_DatePipe();

    switch (field.id) {
      case DynamicField.Disciplines:
        return field.value.map((d) => d.name).join(", ");

      case DynamicField.StartDate:
        return usDatePipe.transform(field.value);

      case DynamicField.EndDate:
        return field.value === undefined
          ? "Indefinitely"
          : usDatePipe.transform(field.value);

      case DynamicField.CounterpartyOrganizationName:
      case DynamicField.CurrentOrganizationName:
        return field.value.trim();
    }
  }

  static extractTagsFromContent(content: string | null): MergeTag[] {
    if (!content) return [];

    const parsedHtml = new DOMParser().parseFromString(
      content,
      EDITOR_CONTENT_FILE_TYPE,
    );
    const mergeTagSelector = AGREEMENT_BUILDER_SELECTORS.mergeTag;

    const elements = parsedHtml.querySelectorAll(mergeTagSelector);

    const ids = Array.from(elements)
      .map((element) => element.id)
      .filter(
        (id): id is string =>
          !!id && Object.values(MergeTag).includes(id as MergeTag),
      );

    return Array.from(new Set(ids)) as MergeTag[];
  }

  static replaceDynamicFieldsWithDefaults(content: string): string {
    const parsedHtml = new DOMParser().parseFromString(
      content,
      EDITOR_CONTENT_FILE_TYPE,
    );
    const mergeTagSelector = AGREEMENT_BUILDER_SELECTORS.mergeTag;

    const elements = parsedHtml.querySelectorAll(mergeTagSelector);

    elements.forEach((element) => {
      element.innerHTML = `
        <span class="mce-mergetag-affix">[</span>
        ${element.id}
        <span class="mce-mergetag-affix">]</span>`;
    });

    return parsedHtml.body.innerHTML;
  }

  static isSignatoryPlaceholderIncluded(
    content: string | null | undefined,
  ): boolean {
    if (!content) return false;

    const parsedHtml = new DOMParser().parseFromString(
      content,
      EDITOR_CONTENT_FILE_TYPE,
    );
    const signatoryPlaceholderId = AGREEMENT_BUILDER_SIGNATORY_PLACEHOLDER_ID;

    return !!parsedHtml.getElementById(signatoryPlaceholderId);
  }
}
