import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { ClinicService } from "@platform-app/app/core/api/services";
import { catchError, finalize, tap } from "rxjs";
import {
  GetClinicAddressesClinicAddress,
  SaveClinicAddressesAddressInfo,
} from "@platform-app/app/core/api/models";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";

export interface ClinicLocationsStateModel {
  dataLoading: boolean;
  saveLoading: boolean;
  addresses:
    | GetClinicAddressesClinicAddress[]
    | SaveClinicAddressesAddressInfo[]
    | null;
  error: string | null;
}

export class LoadCurrentClinicAddresses {
  static readonly type = "[Clinic Locations] Load Current Clinic Addresses";
  constructor() {}
}

export class SaveClinicAddresses {
  static readonly type = "[Clinic Locations] Save Clinic Addresses";
  constructor(
    public payload: {
      addresses: SaveClinicAddressesAddressInfo[];
    },
  ) {}
}

const DEFAULT_CLINIC_LOCATIONS_STATE: ClinicLocationsStateModel = {
  dataLoading: false,
  saveLoading: false,
  addresses: null,
  error: null,
};

@State<ClinicLocationsStateModel>({
  name: "ClinicLocations",
  defaults: DEFAULT_CLINIC_LOCATIONS_STATE,
})
@Injectable()
export class ClinicLocationsState extends StateToDefaultResetter<ClinicLocationsStateModel> {
  constructor(
    private clinicService: ClinicService,
    private store: Store,
  ) {
    super(DEFAULT_CLINIC_LOCATIONS_STATE);
  }

  @Selector()
  static saveLoading(state: ClinicLocationsStateModel) {
    return state.saveLoading;
  }

  @Selector()
  static dataLoading(state: ClinicLocationsStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static error(state: ClinicLocationsStateModel) {
    return state.error;
  }

  @Selector()
  static clinicAddresses(state: ClinicLocationsStateModel) {
    return state.addresses;
  }

  @Action(LoadCurrentClinicAddresses)
  getClinicAddresses(ctx: StateContext<ClinicLocationsStateModel>) {
    ctx.patchState({
      dataLoading: true,
      error: null,
    });

    const clinicId = this.store.selectSnapshot(AuthState.user)?.organization
      ?.id;

    if (!clinicId) {
      throw new Error("Current User does not have Organization id");
    }

    return this.clinicService
      .clinicClinicIdAddressesGet({ clinicId: clinicId })
      .pipe(
        tap((response) => {
          ctx.patchState({
            addresses: response,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            error: error.error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(SaveClinicAddresses)
  saveClinicAddresses(
    ctx: StateContext<ClinicLocationsStateModel>,
    action: SaveClinicAddresses,
  ) {
    ctx.patchState({
      saveLoading: true,
      error: null,
    });

    const clinicId = this.store.selectSnapshot(AuthState.user)?.organization
      ?.id;

    if (!clinicId) {
      throw new Error("Current User does not have Organization id");
    }

    return this.clinicService
      .clinicClinicIdAddressesPut({
        clinicId: clinicId,
        body: action.payload.addresses,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            addresses: response,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            error: error.error,
          });

          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            saveLoading: false,
          });
        }),
      );
  }
}
