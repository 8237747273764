import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  EDITOR_CONTENT_FILE_TYPE,
  MERGE_TAGS,
} from "@platform-app/app/agreement-builder/shared/constants";
import { EditorDynamicFieldsUtility } from "@platform-app/app/agreement-builder/shared/editor-dynamic-fields.utility";
import {
  AgreementDynamicFieldsModel,
  CurrentAgreementModel,
  DynamicFieldModel,
  DynamicFieldUpdatedModel,
  MergeTagModel,
} from "@platform-app/app/agreement-builder/shared/models";
import {
  AffiliationAgreementData,
  ContactData,
  GetAffiliationAgreementCommentThreadsCommentThread,
  GetAffiliationAgreementTemplatesTemplate,
  OrganizationData,
  PartnerInvitation,
  PartnerInvitationErrorCode,
  PartnerInvitationType,
  ProvideChangesForAffiliationAgreementAffiliationAgreementBody,
  UserGroup,
} from "@platform-app/app/core/api/models";
import {
  AffiliationAgreementsService,
  ApplicationsService,
} from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { DateUtility } from "@platform-app/app/main/shared/utilities/date.utility";
import { catchError, finalize, switchMap, tap } from "rxjs";

export interface AgreementBuilderStateModel {
  agreementDynamicFields: AgreementDynamicFieldsModel;
  currentAgreement: CurrentAgreementModel | null;
  lastUpdatedFieldModel: DynamicFieldUpdatedModel | null;
  mergeTags: MergeTagModel[];
  templateOptions: GetAffiliationAgreementTemplatesTemplate[];
  selectedTemplateId: string | null;
  builderContent: string | null;
  builderCommentThreads: GetAffiliationAgreementCommentThreadsCommentThread[];
  editorCommentThreadsIds: string[];
  draftCommentThreadId: string | null;
  sendInvitationLoading: boolean;
  saveTemplateContentLoading: boolean;
  renameTemplateLoading: boolean;
  negotiateChangesLoading: boolean;
  saveAgreementChangesLoading: boolean;
  acceptAgreementLoading: boolean;
  isAvailableForNegotiation: boolean;
  applicationId: string | null;
  opportunityId: string | null;
  invitePartnerErrors: {
    message: string;
    code?: PartnerInvitationErrorCode;
  }[];
}

const defaultState: AgreementBuilderStateModel = {
  agreementDynamicFields: {
    agreementName: null,
    currentOrganizationName: null,
    counterpartyOrganizationName: null,
    counterpartyOrganizationId: null,
    counterpartyOrganizationGoogleId: null,
    startDate: null,
    endDate: null,
    noEndDate: false,
    disciplines: null,
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactPhoneNumber: null,
  },
  currentAgreement: null,
  lastUpdatedFieldModel: null,
  mergeTags: MERGE_TAGS,
  templateOptions: [],
  selectedTemplateId: null,
  builderContent: null,
  builderCommentThreads: [],
  editorCommentThreadsIds: [],
  draftCommentThreadId: null,
  sendInvitationLoading: false,
  saveTemplateContentLoading: false,
  renameTemplateLoading: false,
  negotiateChangesLoading: false,
  saveAgreementChangesLoading: false,
  acceptAgreementLoading: false,
  isAvailableForNegotiation: false,
  applicationId: null,
  opportunityId: null,
  invitePartnerErrors: [],
};

export class UpdateAgreementField {
  static readonly type = "[Agreement Builder page] Update Agreement Field";
  constructor(public payload: { field: DynamicFieldModel; dynamic: boolean }) {}
}

export class GetCurrentAffiliationAgreement {
  static readonly type =
    "[Agreement Builder page] Get Current Affiliation Agreement";
  constructor(public payload: { agreementId: string }) {}
}

export class EditorContentUpdated {
  static readonly type = "[Agreement Builder page] Editor Content Updated";
  constructor(public payload: { content: string | null }) {}
}

export class UpdateMergeTagsList {
  static readonly type = "[Agreement Builder page] Update Merge Tags List";
  constructor(public payload: { tags: MergeTagModel[] }) {}
}

export class LoadTemplateOptions {
  static readonly type = "[Agreement Builder page] Load Template Options";
}

export class AgreementTemplateSelected {
  static readonly type = "[Agreement Builder page] Agreement Template Selected";
  constructor(public payload: { templateId: string | null }) {}
}

export class AddAgreementTemplate {
  static readonly type = "[Agreement Builder page] Add Agreement Template";
  constructor(public payload: { templateName: string | null }) {}
}

export class UpdateSelectedAgreementTemplateContent {
  static readonly type =
    "[Agreement Builder page] Update Agreement Template Content";
  constructor(public payload: { templateName: string }) {}
}

export class RenameAgreementTemplate {
  static readonly type = "[Agreement Builder page] Rename Agreement Template";
  constructor(public payload: { templateId: string; templateName: string }) {}
}

export class DeleteAgreementTemplate {
  static readonly type = "[Agreement Builder page] Delete Agreement Template";
  constructor(public payload: { templateId: string }) {}
}

export class SendPartnerInvitation {
  static readonly type = "[Agreement Builder page] Send Partner Invitation";
  constructor(public payload: { invitationMessage: string | null }) {}
}

export class LoadCurrentAgreementBuilderCommentThreads {
  static readonly type =
    "[Agreement Builder page] Load Agreement Builder Comment Threads";
}

export class UpdateEditorCommentThreadsIds {
  static readonly type =
    "[Agreement Builder page] Update Editor Comment Threads Ids";
  constructor(public payload: { ids: string[] }) {}
}

export class SetDraftCommentThreadId {
  static readonly type = "[Agreement Builder page] Set Draft Comment Id";
  constructor(public payload: { commentId: string | null }) {}
}

export class SyncCurrentAgreementBuilderContentToBackend {
  static readonly type =
    "[Agreement Builder page] Sync Builder Content To Backend";
}

export class RequestChangesForCurrentAgreement {
  static readonly type =
    "[Agreement Builder page] Request Changes For Agreement";
  constructor(public payload: { message: string | null }) {}
}

export class ProvideChangesForCurrentAgreement {
  static readonly type =
    "[Agreement Builder page] Provide Changes For Agreement";
  constructor(
    public payload: {
      comment: string | null;
      submitChanges: boolean;
    },
  ) {}
}

export class AcceptCurrentAgreement {
  static readonly type = "[Agreement Builder page] Accept Current Agreement";
}

export class ResetAgreementBuilderState {
  static readonly type =
    "[Agreement Builder page] Reset Agreement Builder State";
}

export class GetApplication {
  static readonly type = "[Agreement Builder page] Get Application";
  constructor(public payload: { applicationId: string }) {}
}

export class SaveAgreementAsDraft {
  static readonly type = "[Agreement Builder page] Save Agreement As Draft";
}

@State<AgreementBuilderStateModel>({
  name: "AgreementBuilder",
  defaults: defaultState,
})
@Injectable()
export class AgreementBuilderState {
  private readonly affiliationAgreementsService = inject(
    AffiliationAgreementsService,
  );
  private readonly store = inject(Store);
  private readonly applicationService = inject(ApplicationsService);

  @Selector()
  static agreementDynamicFields(state: AgreementBuilderStateModel) {
    return state.agreementDynamicFields;
  }

  @Selector()
  static currentAgreement(state: AgreementBuilderStateModel) {
    return state.currentAgreement;
  }

  @Selector()
  static lastUpdatedField(state: AgreementBuilderStateModel) {
    return state.lastUpdatedFieldModel;
  }

  @Selector()
  static mergeTags(state: AgreementBuilderStateModel) {
    return state.mergeTags;
  }

  @Selector()
  static templateOptions(state: AgreementBuilderStateModel) {
    return state.templateOptions;
  }

  @Selector()
  static selectedTemplateId(state: AgreementBuilderStateModel) {
    return state.selectedTemplateId;
  }

  @Selector()
  static sendInvitationLoading(state: AgreementBuilderStateModel) {
    return state.sendInvitationLoading;
  }

  @Selector()
  static saveTemplateContentLoading(state: AgreementBuilderStateModel) {
    return state.saveTemplateContentLoading;
  }

  @Selector()
  static renameTemplateLoading(state: AgreementBuilderStateModel) {
    return state.renameTemplateLoading;
  }

  @Selector()
  static negotiateChangesLoading(state: AgreementBuilderStateModel) {
    return state.negotiateChangesLoading;
  }

  @Selector()
  static saveAgreementChangesLoading(state: AgreementBuilderStateModel) {
    return state.saveAgreementChangesLoading;
  }

  @Selector()
  static builderContent(state: AgreementBuilderStateModel) {
    return state.builderContent;
  }

  @Selector()
  static acceptAgreementLoading(state: AgreementBuilderStateModel) {
    return state.acceptAgreementLoading;
  }

  @Selector()
  static builderCommentThreads(state: AgreementBuilderStateModel) {
    const { editorCommentThreadsIds, builderCommentThreads } = state;

    const editorThreads = editorCommentThreadsIds
      .map((id) => builderCommentThreads.find((thread) => thread.id === id))
      .filter((thread) => thread !== undefined);

    const additionalThreads = builderCommentThreads.filter(
      (thread) => !editorCommentThreadsIds.includes(thread.id),
    );

    return [additionalThreads, editorThreads].flat();
  }

  @Selector()
  static draftCommentThreadId(state: AgreementBuilderStateModel) {
    return state.draftCommentThreadId;
  }

  @Selector()
  static isAvailableForNegotiation(state: AgreementBuilderStateModel) {
    return state.isAvailableForNegotiation;
  }

  @Selector()
  static applicationId(state: AgreementBuilderStateModel) {
    return state.applicationId;
  }

  @Selector()
  static opportunityId(state: AgreementBuilderStateModel) {
    return state.opportunityId;
  }

  @Selector()
  static invitePartnerErrors(state: AgreementBuilderStateModel) {
    return state.invitePartnerErrors;
  }

  @Action(UpdateAgreementField)
  updateAgreementField(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateAgreementField,
  ) {
    const stateSnapshot = ctx.getState();
    const { field, dynamic } = action.payload;

    ctx.patchState({
      agreementDynamicFields: {
        ...stateSnapshot.agreementDynamicFields,
        [field.id]: field.value,
      },
    });

    if (dynamic) {
      const tag = stateSnapshot.mergeTags.find(
        (t) => t.fieldId === field.id,
      )?.value;
      if (tag) {
        const value =
          EditorDynamicFieldsUtility.getStringFromFieldValue(field) ?? null;
        ctx.patchState({
          lastUpdatedFieldModel: {
            tag,
            value,
          },
        });
      }
    }
  }

  @Action(GetCurrentAffiliationAgreement)
  getCurrentAffiliationAgreement(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: GetCurrentAffiliationAgreement,
  ) {
    const { agreementId } = action.payload;

    return this.loadAgreementBuilderContent(agreementId, ctx);
  }

  @Action(EditorContentUpdated)
  setEditorContent(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: EditorContentUpdated,
  ) {
    ctx.patchState({
      builderContent: action.payload.content,
    });
  }

  @Action(UpdateMergeTagsList)
  updateMergeTagsList(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateMergeTagsList,
  ) {
    ctx.patchState({
      mergeTags: action.payload.tags,
    });
  }

  @Action(LoadTemplateOptions)
  loadTemplateOptions(ctx: StateContext<AgreementBuilderStateModel>) {
    return this.loadAgreementTemplateOptions(ctx);
  }

  @Action(AgreementTemplateSelected)
  agreementTemplateSelected(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: AgreementTemplateSelected,
  ) {
    ctx.patchState({
      selectedTemplateId: action.payload.templateId,
    });
  }

  @Action(AddAgreementTemplate)
  addAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: AddAgreementTemplate,
  ) {
    ctx.patchState({ saveTemplateContentLoading: true });

    const { templateName } = action.payload;
    const { agreementDynamicFields: agreement } = ctx.getState();

    if (!templateName && !agreement.agreementName)
      throw new Error("Template name is missing. Template cannot be saved.");

    const name =
      templateName ??
      this.generateUniqueTemplateName(agreement.agreementName!, ctx);

    const htmlFile = this.getBuilderContentHtmlFile(name, ctx, true);

    return this.affiliationAgreementsService
      .addAffiliationAgreementTemplate({
        body: { Body: { templateName: name }, File: htmlFile },
      })
      .pipe(
        switchMap((response) =>
          this.loadAgreementTemplateOptions(ctx).pipe(
            tap(() =>
              ctx.patchState({ selectedTemplateId: response.addedTemplateId }),
            ),
          ),
        ),
        finalize(() => ctx.patchState({ saveTemplateContentLoading: false })),
      );
  }

  @Action(UpdateSelectedAgreementTemplateContent)
  updateSelectedAgreementTemplateContent(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateSelectedAgreementTemplateContent,
  ) {
    ctx.patchState({ saveTemplateContentLoading: true });

    const { templateName } = action.payload;
    const { selectedTemplateId } = ctx.getState();

    if (!selectedTemplateId) throw new Error("Template cannot be saved.");

    const htmlFile = this.getBuilderContentHtmlFile(templateName, ctx, true);

    return this.affiliationAgreementsService
      .updateAffiliationAgreementTemplateContent({
        templateId: selectedTemplateId,
        body: { content: htmlFile },
      })
      .pipe(
        finalize(() => ctx.patchState({ saveTemplateContentLoading: false })),
      );
  }

  @Action(RenameAgreementTemplate)
  renameAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: RenameAgreementTemplate,
  ) {
    ctx.patchState({ renameTemplateLoading: true });

    const { templateId, templateName } = action.payload;

    return this.affiliationAgreementsService
      .renameAffiliationAgreementTemplate({
        templateId,
        body: { templateName },
      })
      .pipe(
        switchMap(() => this.loadAgreementTemplateOptions(ctx)),
        finalize(() => ctx.patchState({ renameTemplateLoading: false })),
      );
  }

  @Action(DeleteAgreementTemplate)
  deleteAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: DeleteAgreementTemplate,
  ) {
    const { templateId } = action.payload;
    const { selectedTemplateId } = ctx.getState();

    return this.affiliationAgreementsService
      .deleteAffiliationAgreementTemplate({ templateId })
      .pipe(
        tap(() => {
          if (selectedTemplateId === templateId)
            ctx.patchState({ selectedTemplateId: null });
        }),
        switchMap(() => this.loadAgreementTemplateOptions(ctx)),
      );
  }

  @Action(SendPartnerInvitation)
  sendPartnerInvitation(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SendPartnerInvitation,
  ) {
    ctx.patchState({ sendInvitationLoading: true });

    const isCurrentUserSchool =
      this.store.selectSnapshot(AuthState.user)?.roleGroup ===
      UserGroup.SchoolUser;

    const { selectedTemplateId, agreementDynamicFields } = ctx.getState();

    const htmlFile = this.getBuilderContentHtmlFile(
      agreementDynamicFields.agreementName!,
      ctx,
    );

    const jsonBody = {
      affiliationAgreement: this.getAffiliationAgreementBody(
        agreementDynamicFields,
      ),
      contact: this.getContactDataBody(
        agreementDynamicFields,
        action.payload.invitationMessage,
      ),
      type: PartnerInvitationType.New,
      createdWithBuilder: true,
      builderContentTemplateId: selectedTemplateId,
    } satisfies PartnerInvitation;

    const body = { Invitation: jsonBody, Files: [htmlFile] };

    const request = isCurrentUserSchool
      ? this.affiliationAgreementsService.affiliationAgreementsInvitePartnerClinicPost(
          { body },
        )
      : this.affiliationAgreementsService.affiliationAgreementsInvitePartnerSchoolPost(
          { body },
        );

    return request.pipe(
      catchError((response) => {
        ctx.patchState({ invitePartnerErrors: response.error.errors });
        throw response.error.errors;
      }),
      finalize(() => ctx.patchState({ sendInvitationLoading: false })),
    );
  }

  @Action(SaveAgreementAsDraft)
  saveAgreementAsDraft(ctx: StateContext<AgreementBuilderStateModel>) {
    ctx.patchState({ sendInvitationLoading: true });

    const {
      selectedTemplateId,
      agreementDynamicFields,
      applicationId,
      currentAgreement,
    } = ctx.getState();

    const htmlFile = this.getBuilderContentHtmlFile(
      agreementDynamicFields.agreementName!,
      ctx,
    );

    if (currentAgreement) {
      const body = {
        Body: this.getAffiliationAgreementBody(agreementDynamicFields),
        File: htmlFile,
      };

      return this.affiliationAgreementsService
        .updateDraftAffiliationAgreement({ id: currentAgreement.id, body })
        .pipe(finalize(() => ctx.patchState({ sendInvitationLoading: false })));
    }

    const jsonBody = {
      data: this.getAffiliationAgreementBody(agreementDynamicFields),
      builderContentTemplateId: selectedTemplateId,
      applicationId,
    };

    const body = { Body: jsonBody, File: htmlFile };

    return this.affiliationAgreementsService
      .createDraftAffiliationAgreement({ body })
      .pipe(finalize(() => ctx.patchState({ sendInvitationLoading: false })));
  }

  @Action(LoadCurrentAgreementBuilderCommentThreads)
  loadAgreementBuilderCommentThreads(
    ctx: StateContext<AgreementBuilderStateModel>,
  ) {
    const { id } = ctx.getState().currentAgreement!;

    return this.affiliationAgreementsService
      .getAffiliationAgreementCommentThreads({
        id,
      })
      .pipe(
        tap((response) =>
          ctx.patchState({
            builderCommentThreads: response.commentThreads,
            isAvailableForNegotiation:
              response.isAgreementAvailableForNegotiation,
          }),
        ),
      );
  }

  @Action(UpdateEditorCommentThreadsIds)
  updateEditorCommentThreadsIds(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateEditorCommentThreadsIds,
  ) {
    ctx.patchState({ editorCommentThreadsIds: action.payload.ids });
  }

  @Action(SetDraftCommentThreadId)
  setDraftCommentThreadId(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SetDraftCommentThreadId,
  ) {
    ctx.patchState({ draftCommentThreadId: action.payload.commentId });
  }

  @Action(SyncCurrentAgreementBuilderContentToBackend)
  syncBuilderContentToBackend(ctx: StateContext<AgreementBuilderStateModel>) {
    const { id, name } = ctx.getState().currentAgreement!;

    const htmlFile = this.getBuilderContentHtmlFile(name, ctx);

    return this.affiliationAgreementsService.updateAffiliationAgreementBuilderContent(
      {
        id,
        body: { file: htmlFile },
      },
    );
  }

  @Action(RequestChangesForCurrentAgreement)
  requestChangesForAgreement(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: RequestChangesForCurrentAgreement,
  ) {
    ctx.patchState({ negotiateChangesLoading: true });

    const { id } = ctx.getState().currentAgreement!;
    const { message } = action.payload;

    return this.affiliationAgreementsService
      .requestChangesForAffiliationAgreement({
        id,
        body: { message },
      })
      .pipe(finalize(() => ctx.patchState({ negotiateChangesLoading: false })));
  }

  @Action(ProvideChangesForCurrentAgreement)
  provideChangesForAgreement(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: ProvideChangesForCurrentAgreement,
  ) {
    const { comment, submitChanges } = action.payload;
    const { agreementDynamicFields } = ctx.getState();
    const { id } = ctx.getState().currentAgreement!;

    ctx.patchState({
      negotiateChangesLoading: submitChanges,
      saveAgreementChangesLoading: !submitChanges,
    });

    const jsonBody = this.getUpdatedAffiliationAgreementBody(
      agreementDynamicFields,
      comment,
      submitChanges,
    );
    const htmlFile = this.getBuilderContentHtmlFile(
      agreementDynamicFields.agreementName!,
      ctx,
    );

    const body = { Body: jsonBody, File: htmlFile };

    return this.affiliationAgreementsService
      .provideChangesForAffiliationAgreement({
        id,
        body,
      })
      .pipe(
        finalize(() =>
          ctx.patchState({
            negotiateChangesLoading: false,
            saveAgreementChangesLoading: false,
          }),
        ),
      );
  }

  @Action(AcceptCurrentAgreement)
  acceptCurrentAgreement(ctx: StateContext<AgreementBuilderStateModel>) {
    const { currentAgreement } = ctx.getState();

    if (!currentAgreement) throw new Error("Current agreement is not set.");

    ctx.patchState({
      acceptAgreementLoading: true,
    });

    const htmlFile = this.getBuilderContentHtmlFile(currentAgreement.name, ctx);

    const body = { Body: { acceptWithBuilder: true }, Files: [htmlFile] };

    return this.affiliationAgreementsService
      .acceptAffiliationAgreement({
        id: currentAgreement.id,
        body,
      })
      .pipe(
        finalize(() =>
          ctx.patchState({
            acceptAgreementLoading: false,
          }),
        ),
      );
  }

  @Action(ResetAgreementBuilderState)
  resetAgreementBuilderState(ctx: StateContext<AgreementBuilderStateModel>) {
    ctx.setState(defaultState);
  }

  @Action(GetApplication)
  getApplication(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: GetApplication,
  ) {
    const { applicationId } = action.payload;
    const { agreementDynamicFields } = ctx.getState();

    return this.applicationService
      .getApplicationForCounterparty({ id: applicationId })
      .pipe(
        tap((response) =>
          ctx.patchState({
            agreementDynamicFields: {
              ...agreementDynamicFields,
              counterpartyOrganizationName: response.organizationName,
              counterpartyOrganizationId: response.organizationId,
            },
            applicationId: response.applicationId,
            opportunityId: response.opportunityId,
          }),
        ),
      );
  }

  private getAffiliationAgreementBody(
    agreement: AgreementDynamicFieldsModel,
  ): AffiliationAgreementData {
    const endDate = agreement.endDate ? new Date(agreement.endDate) : null;

    return {
      name: agreement.agreementName!,
      disciplinesIds: agreement
        .disciplines!.map((d) => d.id)
        .filter((id: number) => id !== 0),
      endDate: DateUtility.convertToDateOnlyString(endDate),
      startDate: DateUtility.convertToDateOnlyString(
        new Date(agreement.startDate!),
      )!,
      organization: {
        id: agreement.counterpartyOrganizationId,
        googlePlaceId: agreement.counterpartyOrganizationGoogleId,
        name: agreement.counterpartyOrganizationName,
      } as OrganizationData,
    } satisfies AffiliationAgreementData;
  }

  private getContactDataBody(
    agreement: AgreementDynamicFieldsModel,
    message: string | null,
  ): ContactData | null {
    if (agreement.counterpartyOrganizationId) return null;

    return {
      firstName: agreement.contactFirstName!,
      lastName: agreement.contactLastName!,
      email: agreement.contactEmail!,
      phoneNumber: agreement.contactPhoneNumber ?? null,
      message: message!,
    } satisfies ContactData;
  }

  private getUpdatedAffiliationAgreementBody(
    agreement: AgreementDynamicFieldsModel,
    comment: string | null,
    submitChanges: boolean,
  ): ProvideChangesForAffiliationAgreementAffiliationAgreementBody {
    const endDate = agreement.endDate ? new Date(agreement.endDate) : null;

    return {
      name: agreement.agreementName!,
      changesComment: comment,
      disciplinesIds: agreement
        .disciplines!.map((d) => d.id)
        .filter((id: number) => id !== 0),
      endDate: DateUtility.convertToDateOnlyString(endDate),
      startDate: DateUtility.convertToDateOnlyString(
        new Date(agreement.startDate!),
      )!,
      submitChanges,
    } satisfies ProvideChangesForAffiliationAgreementAffiliationAgreementBody;
  }

  private loadAgreementTemplateOptions(
    ctx: StateContext<AgreementBuilderStateModel>,
  ) {
    return this.affiliationAgreementsService
      .getAffiliationAgreementTemplates()
      .pipe(tap((response) => ctx.patchState({ templateOptions: response })));
  }

  private loadAgreementBuilderContent(
    agreementId: string,
    ctx: StateContext<AgreementBuilderStateModel>,
  ) {
    const { agreementDynamicFields } = ctx.getState();

    return this.affiliationAgreementsService
      .getAffiliationAgreementBuilderContent({
        id: agreementId,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            agreementDynamicFields: {
              ...agreementDynamicFields,
              agreementName: response.agreementName,
              counterpartyOrganizationId: response.counterpartyOrganization.id,
              counterpartyOrganizationName:
                response.counterpartyOrganization.name,
              startDate: response.startDate,
              endDate: response.noEndDate ? undefined : response.endDate,
              noEndDate: response.noEndDate,
              disciplines: response.disciplines,
            },
            currentAgreement: {
              id: response.agreementId,
              name: response.agreementName,
              status: response.status,
              subStatus: response.subStatus,
              lastUpdatedAt: response.lastUpdatedAt,
              lastUpdatedByUser: response.lastUpdatedByUser,
            },
            builderContent: response.builderContent,
            selectedTemplateId: response.templateId,
          });
        }),
      );
  }

  private generateUniqueTemplateName(
    agreementName: string,
    ctx: StateContext<AgreementBuilderStateModel>,
  ): string {
    const baseTemplateName = `${agreementName} Template`;

    const existingTemplateNames = ctx
      .getState()
      .templateOptions.map((template) => template.name)
      .filter((name) => name.startsWith(baseTemplateName));

    if (!existingTemplateNames.length) return baseTemplateName;

    const templateNumbers = existingTemplateNames.map((name) => {
      const trailingNumberRegex = /\((\d+)\)$/;
      const match = name.match(trailingNumberRegex);
      return match ? parseInt(match[1], 10) : 0;
    });

    const nextNumber = Math.max(0, ...templateNumbers) + 1;

    return `${baseTemplateName} (${nextNumber})`;
  }

  private getBuilderContentHtmlFile(
    fileName: string,
    ctx: StateContext<AgreementBuilderStateModel>,
    getTemplate: boolean = false,
  ): File {
    const { builderContent } = ctx.getState();

    if (!builderContent) throw new Error("Builder content is null or empty.");

    const content = getTemplate
      ? EditorDynamicFieldsUtility.replaceDynamicFieldsWithDefaults(
          builderContent,
        )
      : builderContent;

    return new File([content], `${fileName}.html`, {
      type: EDITOR_CONTENT_FILE_TYPE,
    });
  }
}
