import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  Subject,
  catchError,
  finalize,
  of,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { ClinicService } from "@platform-app/app/core/api/services";
import {
  GetClinicClinicDetails,
  LocationFilterType,
  MapPoint,
  SearchClinicsClinic,
  SearchClinicsRequest,
  ViewPort,
} from "@platform-app/app/core/api/models";

export interface ClinicsStateModel {
  initialClinicsLoad: boolean;
  clinics: SearchClinicsClinic[] | null;
  totalAmount: number;
  currentPage: number;
  selectedClinicDetails: GetClinicClinicDetails | null;
  selectedClinicId: string | null;
  filters: SearchClinicsRequest;
  filterPlaceId: string | null;
  isLoading: boolean;
}

export class LoadClinics {
  static readonly type = "[Clinics Page] Load clinics";
  constructor(public payload?: SearchClinicsRequest) {}
}

export class LoadClinicDetails {
  static readonly type = "[Clinics Page] Load clinic details";
  constructor(public payload: { clinicId: string }) {}
}

export class ClinicSelected {
  static readonly type = "[Clinics Page] Clinic is selected";
  constructor(public payload: { clinicId: string | null }) {}
}

export class UpdateClinicsFilters {
  static readonly type = "[Clinics Page] Update filters";
  constructor(
    public payload: {
      filterType?: LocationFilterType | null;
      filterText?: string | null;
      location?: MapPoint | null;
      radius?: number | null;
      searchText?: string | null;
      viewPort?: ViewPort | null;
      excludeKeywords?: string[] | null;
      includeKeywords?: string[] | null;
      filterPlaceId?: string | null;
    },
  ) {}
}

export class SetInitialClinicsLoadState {
  static readonly type = "[Clinics Page] Initial Clinics load";
}

export class ClearClinicsState {
  static readonly type = "[Clinics Page] Clinics state is cleared";
}

const DEFAULT_CLINICS_STATE: ClinicsStateModel = {
  initialClinicsLoad: true,
  clinics: [],
  totalAmount: 0,
  currentPage: 1,
  selectedClinicDetails: null,
  selectedClinicId: null,
  filters: {
    filterText: null,
    filterType: null,
    location: null,
    radius: null,
    searchText: null,
    viewPort: null,
    excludeKeywords: null,
    includeKeywords: null,
  },
  filterPlaceId: null,
  isLoading: false,
};

@State<ClinicsStateModel>({
  name: "Clinics",
  defaults: DEFAULT_CLINICS_STATE,
})
@Injectable()
export class ClinicsState {
  constructor(private clinicService: ClinicService) {}
  cancelPrevious$ = new Subject<void>();

  @Selector()
  static isLoading(state: ClinicsStateModel) {
    return state.isLoading;
  }

  @Selector()
  static selectedClinicId(state: ClinicsStateModel) {
    return state.selectedClinicId;
  }

  @Selector()
  static selectedClinicDetails(state: ClinicsStateModel) {
    return state.selectedClinicDetails;
  }

  @Selector()
  static clinicFilterType(state: ClinicsStateModel) {
    return state.filters.filterType;
  }

  @Selector()
  static clinicFilterSearchText(state: ClinicsStateModel) {
    return state.filters.searchText;
  }

  @Selector()
  static clinicFilterLocation(state: ClinicsStateModel) {
    return state.filters.location;
  }

  @Selector()
  static clinicFilterLocationRadius(state: ClinicsStateModel) {
    return state.filters.radius;
  }

  @Selector()
  static filterPlaceId(state: ClinicsStateModel) {
    return state.filterPlaceId;
  }

  @Selector()
  static clinicFilterText(state: ClinicsStateModel) {
    return state.filters.filterText;
  }

  @Selector()
  static clinicIncludeKeywords(state: ClinicsStateModel) {
    return state.filters.includeKeywords;
  }

  @Selector()
  static clinicExcludeKeywords(state: ClinicsStateModel) {
    return state.filters.excludeKeywords;
  }

  @Selector()
  static initialClinicsLoad(state: ClinicsStateModel) {
    return state.initialClinicsLoad;
  }

  @Action(LoadClinics)
  loadClinics(ctx: StateContext<ClinicsStateModel>, action: LoadClinics) {
    const stateSnapshot = ctx.getState();

    this.cancelPrevious$.next();

    ctx.patchState({
      isLoading: true,
    });

    return this.clinicService
      .clinicSearchPost({
        body: { ...stateSnapshot.filters },
      })
      .pipe(
        switchMap((response) => {
          ctx.patchState({
            filters: { ...stateSnapshot.filters },
            clinics: response.clinics,
            totalAmount: response.totalAmount,
            currentPage: action.payload?.page || stateSnapshot.currentPage,
          });
          return of(response);
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() => {
          ctx.patchState({
            isLoading: false,
          });
        }),
      );
  }

  @Action(LoadClinicDetails)
  loadClinicDetails(
    ctx: StateContext<ClinicsStateModel>,
    action: LoadClinicDetails,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.clinicService
      .clinicClinicIdGet({
        clinicId: action.payload.clinicId,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            selectedClinicDetails: response.clinic,
          });
        }),
        catchError((error) => {
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            isLoading: false,
          });
        }),
      );
  }

  @Action(ClinicSelected)
  clinicSelectedAction(
    ctx: StateContext<ClinicsStateModel>,
    action: ClinicSelected,
  ) {
    const { clinicId } = action.payload;

    if (clinicId) {
      ctx.dispatch(new LoadClinicDetails({ clinicId }));
      ctx.patchState({
        selectedClinicId: clinicId,
      });
    } else {
      ctx.patchState({
        selectedClinicId: null,
        selectedClinicDetails: null,
      });
    }
  }

  @Action(UpdateClinicsFilters)
  updateFilters(
    ctx: StateContext<ClinicsStateModel>,
    action: UpdateClinicsFilters,
  ) {
    const stateSnapshot = ctx.getState();
    const {
      filterText = stateSnapshot.filters.filterText,
      filterType = stateSnapshot.filters.filterType,
      location = stateSnapshot.filters.location,
      radius = stateSnapshot.filters.radius,
      searchText = stateSnapshot.filters.searchText,
      viewPort = stateSnapshot.filters.viewPort,
      excludeKeywords = stateSnapshot.filters.excludeKeywords,
      includeKeywords = stateSnapshot.filters.includeKeywords,
      filterPlaceId = stateSnapshot.filterPlaceId,
    } = action.payload;

    ctx.patchState({
      filters: {
        filterText,
        filterType,
        location,
        radius,
        searchText,
        viewPort,
        excludeKeywords,
        includeKeywords,
      },
      filterPlaceId,
      isLoading: true,
    });
    ctx.dispatch(
      new LoadClinics({
        filterText: action.payload.filterText || null,
        filterType: action.payload.filterType || null,
        location: action.payload.location || null,
        radius: action.payload.radius || null,
        searchText: action.payload.searchText || null,
        viewPort: action.payload.viewPort || null,
        excludeKeywords: action.payload.excludeKeywords || null,
        includeKeywords: action.payload.includeKeywords || null,
      }),
    );
  }

  @Action(SetInitialClinicsLoadState)
  setInitialClinicsLoadState(ctx: StateContext<ClinicsStateModel>) {
    ctx.patchState({ initialClinicsLoad: false });
  }

  @Action(ClearClinicsState)
  clearClinicsState(ctx: StateContext<ClinicsStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      ...DEFAULT_CLINICS_STATE,
    });
  }
}
