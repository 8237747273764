import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { GetOrganizationInfoResponse } from "@platform-app/app/core/api/models/get-organization-info-response";
import { finalize, forkJoin, Observable, tap } from "rxjs";
import {
  ClinicService,
  OrganizationService,
  PublishedOpportunityService,
} from "@platform-app/app/core/api/services";
import { SearchPublishedOpportunitiesOpportunity } from "@platform-app/app/core/api/models/search-published-opportunities-opportunity";
import { GetPublishedOpportunityOpportunityDetails } from "@platform-app/app/core/api/models/get-published-opportunity-opportunity-details";
import { GetOrganizationStaffStaff } from "@platform-app/app/core/api/models/get-organization-staff-staff";
import {
  GetClinicAddressesClinicAddress,
  GetOperationalDetailsOperationalDetails,
  GetOrganizationStaffResponse,
  GetOrganizationSubteamsResponse,
  GetOrganizationSubteamsTeam,
  GetPublishedOpportunitySeatsAvailabilityCalculationType,
} from "@platform-app/app/core/api/models";
import { DateUtility } from "../shared/utilities/date.utility";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";

export interface ClinicProfileStateModel {
  clinicOpportunities: SearchPublishedOpportunitiesOpportunity[];
  clinicAddresses: GetClinicAddressesClinicAddress[];
  clinicOperationalDetails: GetOperationalDetailsOperationalDetails | null;
  selectedOpportunityDetails: GetPublishedOpportunityOpportunityDetails | null;
  currentClinicOpportunitiesPage: number;
  opportunitiesPageSize: number;
  totalOpportunitiesInClinic: number;
  opportunitiesLoading: boolean;
  clinicOrganization: GetOrganizationInfoResponse | null;
  staff: GetOrganizationStaffStaff[] | null;
  teams: GetOrganizationSubteamsTeam[] | null;
  error: null;
}

export class LoadClinicData {
  static readonly type = "[Clinic Profile] Get Clinic Details";
  constructor(public payload: { id: string }) {}
}

export class LoadSelectedOpportunityDetails {
  static readonly type = "[Clinic Profile] Load Opportunity Details";
  constructor(public payload: { id: string }) {}
}

export class ClearSelectedOpportunityDetails {
  static readonly type = "[Clinic Profile] Clear Opportunity Details";
}

export class ChangeOpportunitiesPage {
  static readonly type = "[Clinic Profile] Change Page";
  constructor(public payload: { page: number }) {}
}

export class ChangeOpportunitiesPageSize {
  static readonly type = "[Clinic Profile] Change Page Size";
  constructor(public payload: { size: number }) {}
}

const DEFAULT_CLINIC_PROFILE_STATE: ClinicProfileStateModel = {
  clinicOpportunities: [],
  clinicAddresses: [],
  clinicOperationalDetails: null,
  selectedOpportunityDetails: null,
  currentClinicOpportunitiesPage: 1,
  opportunitiesPageSize: 10,
  totalOpportunitiesInClinic: 0,
  clinicOrganization: null,
  staff: null,
  opportunitiesLoading: false,
  teams: null,
  error: null,
};

@State<ClinicProfileStateModel>({
  name: "ClinicProfile",
  defaults: {
    clinicOpportunities: [],
    clinicAddresses: [],
    clinicOperationalDetails: null,
    selectedOpportunityDetails: null,
    currentClinicOpportunitiesPage: 1,
    opportunitiesPageSize: 10,
    totalOpportunitiesInClinic: 0,
    clinicOrganization: null,
    staff: null,
    opportunitiesLoading: false,
    teams: null,
    error: null,
  },
})
@Injectable()
export class ClinicProfileState extends StateToDefaultResetter<ClinicProfileStateModel> {
  constructor(
    private readonly organizationService: OrganizationService,
    private readonly clinicService: ClinicService,
    private readonly publishedOpportunityService: PublishedOpportunityService,
  ) {
    super(DEFAULT_CLINIC_PROFILE_STATE);
  }

  @Selector()
  static clinicOpportunities(state: ClinicProfileStateModel) {
    return state.clinicOpportunities;
  }

  @Selector()
  static clinicAddresses(state: ClinicProfileStateModel) {
    return state.clinicAddresses;
  }

  @Selector()
  static clinicOperationalDetails(state: ClinicProfileStateModel) {
    return state.clinicOperationalDetails;
  }

  @Selector()
  static selectedOpportunityDetails(state: ClinicProfileStateModel) {
    return state.selectedOpportunityDetails;
  }

  @Selector()
  static clinicOrganization(state: ClinicProfileStateModel) {
    return state.clinicOrganization;
  }

  @Selector()
  static clinicStaff(state: ClinicProfileStateModel) {
    return state.staff;
  }

  @Selector()
  static opportunitiesLoading(state: ClinicProfileStateModel) {
    return state.opportunitiesLoading;
  }

  @Selector()
  static totalOpportunitiesInClinic(state: ClinicProfileStateModel) {
    return state.totalOpportunitiesInClinic;
  }

  @Selector()
  static currentClinicOpportunitiesPage(state: ClinicProfileStateModel) {
    return state.currentClinicOpportunitiesPage;
  }

  @Selector()
  static opportunitiesPageSize(state: ClinicProfileStateModel) {
    return state.opportunitiesPageSize;
  }

  @Selector()
  static teams(state: ClinicProfileStateModel) {
    return state.teams;
  }

  @Action(ClearSelectedOpportunityDetails)
  clearSelectedOpportunityDetails(ctx: StateContext<ClinicProfileStateModel>) {
    ctx.patchState({ selectedOpportunityDetails: null });
  }

  @Action(ChangeOpportunitiesPage)
  changeOpportunitiesPage(
    ctx: StateContext<ClinicProfileStateModel>,
    action: ChangeOpportunitiesPage,
  ) {
    const snapshot = ctx.getState();
    const clinicId = snapshot.clinicOrganization?.id;

    ctx.patchState({
      currentClinicOpportunitiesPage: action.payload.page,
      opportunitiesLoading: true,
    });

    this.getClinicOpportunities(clinicId!, ctx)
      .pipe(finalize(() => ctx.patchState({ opportunitiesLoading: false })))
      .subscribe((result) =>
        ctx.patchState({ clinicOpportunities: result.opportunities }),
      );
  }

  @Action(ChangeOpportunitiesPageSize)
  changeOpportunitiesPageSize(
    ctx: StateContext<ClinicProfileStateModel>,
    action: ChangeOpportunitiesPageSize,
  ) {
    const snapshot = ctx.getState();
    const clinicId = snapshot.clinicOrganization?.id;

    ctx.patchState({ opportunitiesPageSize: action.payload.size });

    this.getClinicOpportunities(clinicId!, ctx).subscribe((result) =>
      ctx.patchState({ clinicOpportunities: result.opportunities }),
    );
  }

  @Action(LoadSelectedOpportunityDetails)
  loadSelectedOpportunityDetails(
    ctx: StateContext<ClinicProfileStateModel>,
    action: LoadSelectedOpportunityDetails,
  ) {
    return this.publishedOpportunityService
      .getPublishedOpportunity({
        opportunityId: action.payload.id,
        body: {
          seatsCalculationType:
            GetPublishedOpportunitySeatsAvailabilityCalculationType.ProvideDates,
          studentId: null,
          seatsAvailabilityStartDate: DateUtility.convertToDateOnlyString(
            new Date(),
          ),
          seatsAvailabilityEndDate: DateUtility.convertToDateOnlyString(
            new Date(),
          ),
        },
      })
      .pipe(
        tap((response) =>
          ctx.patchState({ selectedOpportunityDetails: response }),
        ),
      );
  }

  @Action(LoadClinicData)
  getClinic(
    ctx: StateContext<ClinicProfileStateModel>,
    action: LoadClinicData,
  ) {
    const organizationId = action.payload.id;

    return forkJoin([
      this.getClinicInfo(organizationId),
      this.getClinicOpportunities(organizationId, ctx),
      this.getClinicStaff(organizationId),
      this.getClinicAddresses(organizationId),
      this.getClinicOperationalDetails(organizationId),
      this.getTeams(organizationId),
    ]).pipe(
      tap(
        ([
          organizationData,
          opportunitiesData,
          staff,
          addresses,
          clinicOperationalDetails,
          teams,
        ]) => {
          ctx.patchState({
            clinicOpportunities: opportunitiesData.opportunities,
            clinicOrganization: organizationData,
            clinicAddresses: addresses,
            staff: staff.staff,
            clinicOperationalDetails,
            teams: teams.teams,
          });
        },
      ),
    );
  }

  private getClinicOpportunities(
    clinicId: string,
    ctx: StateContext<ClinicProfileStateModel>,
  ) {
    const state = ctx.getState();

    ctx.patchState({ opportunitiesLoading: true });

    return this.publishedOpportunityService
      .opportunitiesPublishedSearchPost({
        body: {
          filters: { clinicIds: [clinicId] },
          pagination: {
            page: state.currentClinicOpportunitiesPage,
            itemsPerPage: state.opportunitiesPageSize,
          },
        },
      })
      .pipe(
        tap((value) =>
          ctx.patchState({ totalOpportunitiesInClinic: value.totalAmount }),
        ),
        finalize(() => ctx.patchState({ opportunitiesLoading: false })),
      );
  }

  private getClinicInfo(
    clinicId: string,
  ): Observable<GetOrganizationInfoResponse> {
    return this.organizationService.organizationOrganizationIdGet({
      organizationId: clinicId,
    });
  }

  private getClinicAddresses(
    clinicId: string,
  ): Observable<GetClinicAddressesClinicAddress[]> {
    return this.clinicService.clinicClinicIdAddressesGet({ clinicId });
  }

  private getClinicStaff(
    clinicId: string,
  ): Observable<GetOrganizationStaffResponse> {
    return this.organizationService.organizationOrganizationIdStaffGet({
      organizationId: clinicId,
    });
  }

  private getClinicOperationalDetails(
    clinicId: string,
  ): Observable<GetOperationalDetailsOperationalDetails> {
    return this.clinicService.clinicClinicIdOperationalDetailsGet({ clinicId });
  }

  private getTeams(
    clinicId: string,
  ): Observable<GetOrganizationSubteamsResponse> {
    return this.organizationService.getOrganizationSubteams({
      organizationId: clinicId,
    });
  }
}
