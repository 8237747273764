import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  SearchApplicationsApplication,
  SearchApplicationsFilters,
} from "@platform-app/app/core/api/models";
import { ApplicationsService } from "@platform-app/app/core/api/services";
import { ApplicationsTabsCount } from "@platform-app/app/main/applications/models";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";
import { finalize, of, Subject, takeUntil, tap } from "rxjs";

export interface ApplicationsStateModel {
  applicationList: SearchApplicationsApplication[] | null;
  firstRow: number;
  searchText: string | null;
  totalCount: number;
  allCount: number;
  draftCount: number;
  underReviewCount: number;
  approvedCount: number;
  rejectedCount: number;
  cancelledCount: number;
  currentPage: number;
  itemsPerPage: number;
  filters: SearchApplicationsFilters;
  dataLoading: boolean;
  error: string | null;
}

export class LoadApplications {
  static readonly type = "[Applications Page] Load Students";
  constructor() {}
}

export class PageRangeChanged {
  static readonly type = "[Applications Page] Page Range Changed";
  constructor(public payload: { itemsPerPage: number }) {}
}

export class PageNumberChanged {
  static readonly type = "[Applications Page] Page Number Changed";
  constructor(public payload: { pageNumber: number }) {}
}

export class UpdateFilters {
  static readonly type = "[Applications Page] Update Filters";
  constructor(public payload: { filters: SearchApplicationsFilters }) {}
}

export class UpdateApplicationsSearchText {
  static readonly type = "[Applications Page] Update Application Search Text";
  constructor(public payload: { searchText: string | null }) {}
}

export class ResetApplicationList {
  static readonly type = "[Applications Page] Reset Application List";
}

export class ClearApplicationList {
  static readonly type = "[Applications Page] Clear Application List";
}

export class SetFirstRow {
  static readonly type = "[Opportunities Listing] Set First Row";
  constructor(public payload: { firstRow: number }) {}
}

const DEFAULT_APPLICATIONS_STATE: ApplicationsStateModel = {
  applicationList: null,
  firstRow: 0,
  searchText: null,
  totalCount: 0,
  allCount: 0,
  draftCount: 0,
  underReviewCount: 0,
  approvedCount: 0,
  rejectedCount: 0,
  cancelledCount: 0,
  currentPage: 1,
  itemsPerPage: 10,
  filters: {
    statuses: null,
  },
  dataLoading: false,
  error: null,
};

@State<ApplicationsStateModel>({
  name: "Applications",
  defaults: {
    applicationList: null,
    firstRow: 0,
    searchText: null,
    totalCount: 0,
    allCount: 0,
    draftCount: 0,
    underReviewCount: 0,
    approvedCount: 0,
    rejectedCount: 0,
    cancelledCount: 0,
    currentPage: 1,
    itemsPerPage: 10,
    filters: {
      statuses: null,
    },
    dataLoading: false,
    error: null,
  },
})
@Injectable()
export class ApplicationsState extends StateToDefaultResetter<ApplicationsStateModel> {
  private cancelPrevious$ = new Subject<void>();

  constructor(private applicationsService: ApplicationsService) {
    super(DEFAULT_APPLICATIONS_STATE);
  }

  @Selector()
  static dataLoading(state: ApplicationsStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static searchText(state: ApplicationsStateModel) {
    return state.searchText;
  }

  @Selector()
  static filterStatuses(state: ApplicationsStateModel) {
    return state.filters.statuses;
  }

  @Selector()
  static applicationList(state: ApplicationsStateModel) {
    return state.applicationList;
  }

  @Selector()
  static totalCount(state: ApplicationsStateModel) {
    return state.totalCount;
  }

  @Selector()
  static itemsPerPage(state: ApplicationsStateModel) {
    return state.itemsPerPage;
  }

  @Selector()
  static applicationsFirstRow(state: ApplicationsStateModel) {
    return state.firstRow;
  }

  @Selector()
  static tabCount(state: ApplicationsStateModel) {
    return {
      total: state.totalCount,
      all: state.allCount,
      draft: state.draftCount,
      underReview: state.underReviewCount,
      approved: state.approvedCount,
      rejected: state.rejectedCount,
      canceled: state.cancelledCount,
    } satisfies ApplicationsTabsCount;
  }

  @Selector()
  static currentPage(state: ApplicationsStateModel) {
    return state.currentPage;
  }

  @Selector()
  static error(state: ApplicationsStateModel) {
    return state.error;
  }

  @Action(LoadApplications)
  loadApplications(ctx: StateContext<ApplicationsStateModel>) {
    return this.getApplications(ctx);
  }

  @Action(PageNumberChanged)
  pageNumberChanged(
    ctx: StateContext<ApplicationsStateModel>,
    action: PageNumberChanged,
  ) {
    ctx.patchState({
      currentPage: action.payload.pageNumber,
    });

    return this.getApplications(ctx);
  }

  @Action(UpdateApplicationsSearchText)
  updateSearchText(
    ctx: StateContext<ApplicationsStateModel>,
    action: UpdateApplicationsSearchText,
  ) {
    ctx.patchState({
      searchText: action.payload.searchText || null,
      currentPage: 1,
      firstRow: 0,
    });
    ctx.dispatch(new LoadApplications());
  }

  @Action(SetFirstRow)
  setApplicationsFirstRow(
    ctx: StateContext<ApplicationsStateModel>,
    action: SetFirstRow,
  ) {
    ctx.patchState({ firstRow: action.payload.firstRow });
  }

  @Action(PageRangeChanged)
  pageRangeChanged(
    ctx: StateContext<ApplicationsStateModel>,
    action: PageRangeChanged,
  ) {
    ctx.patchState({
      itemsPerPage: action.payload.itemsPerPage,
      currentPage: 1,
    });

    return this.getApplications(ctx);
  }

  @Action(UpdateFilters)
  updateFilters(
    ctx: StateContext<ApplicationsStateModel>,
    action: UpdateFilters,
  ) {
    const stateSnapshot = ctx.getState();
    const { statuses = stateSnapshot.filters?.statuses } =
      action.payload.filters;

    ctx.patchState({
      filters: {
        statuses,
      },
      currentPage: 1,
    });

    return this.getApplications(ctx);
  }

  @Action(ResetApplicationList)
  resetApplicationList(ctx: StateContext<ApplicationsStateModel>) {
    ctx.patchState({
      searchText: null,
      currentPage: 1,
      itemsPerPage: 10,
      applicationList: null,
      totalCount: 0,
      filters: { statuses: null },
    });
  }

  @Action(ClearApplicationList)
  clearApplicationList(ctx: StateContext<ApplicationsStateModel>) {
    ctx.patchState({
      applicationList: [],
    });
  }

  private getApplications(ctx: StateContext<ApplicationsStateModel>) {
    const stateSnapshot = ctx.getState();
    this.cancelPrevious$.next();

    if (!stateSnapshot.applicationList) {
      ctx.patchState({
        dataLoading: true,
      });
    }

    return this.applicationsService
      .searchApplications({
        body: {
          searchText: stateSnapshot.searchText,
          pagination: {
            page: stateSnapshot.currentPage,
            itemsPerPage: stateSnapshot.itemsPerPage,
          },
          filters: {
            statuses: stateSnapshot.filters.statuses,
          },
        },
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            applicationList: response.applications,
            totalCount: response.totalCount,
            allCount: response.allCount,
            draftCount: response.draftCount,
            underReviewCount: response.underReviewCount,
            approvedCount: response.approvedCount,
            rejectedCount: response.rejectedCount,
            cancelledCount: response.canceledCount,
          });
          return of(response);
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() =>
          ctx.patchState({
            dataLoading: false,
          }),
        ),
      );
  }
}
