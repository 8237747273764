import { APP_INITIALIZER, Provider, inject } from "@angular/core";
import { Store } from "@ngxs/store";
import { AuthState } from "./auth.state";
import { EMPTY } from "rxjs";
import { RefreshUserInfo } from "@platform-app/app/core/auth/auth.actions";

export const initializeAuth = () => {
  const store = inject(Store);
  return () =>
    store.selectSnapshot(AuthState.isAuthenticated)
      ? store.dispatch(new RefreshUserInfo())
      : EMPTY;
};

export const provideAuthInitializer: () => Provider = () => ({
  provide: APP_INITIALIZER,
  useFactory: initializeAuth,
  multi: true,
  deps: [],
});
