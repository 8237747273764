import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import {
  GetApplication,
  GetCurrentAffiliationAgreement,
} from "@platform-app/app/agreement-builder/agreement-builder.state";
import { catchError, EMPTY, Observable, of } from "rxjs";

export const agreementBuilderResolver: ResolveFn<Observable<void | null>> = (
  route: ActivatedRouteSnapshot,
): Observable<void | null> => {
  const router = inject(Router);
  const agreementId = route.queryParamMap.get("agreementId");
  const applicationId = route.queryParamMap.get("applicationId");
  const store = inject(Store);

  if (agreementId) {
    return store
      .dispatch(new GetCurrentAffiliationAgreement({ agreementId }))
      .pipe(
        catchError(() => {
          router.navigate(["/agreements"]);
          return EMPTY;
        }),
      );
  }

  if (applicationId) {
    return store.dispatch(new GetApplication({ applicationId })).pipe(
      catchError(() => {
        router.navigate(["/agreements"]);
        return EMPTY;
      }),
    );
  }

  return of(null);
};
