import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule } from "@ngxs/store";
import { environment } from "../environments/environment";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { SignUpState } from "./account/sign-up/sign-up.state";
import { AuthState } from "./core/auth/auth.state";
import { importProvidersFrom } from "@angular/core";
import {
  LOCAL_STORAGE_ENGINE,
  NgxsStoragePluginModule,
  SESSION_STORAGE_ENGINE,
} from "@ngxs/storage-plugin";
import { ClinicsState } from "./main/clinics-database/clinics.state";
import { OrganizationInfoState } from "./main/organization-settings-page/profile-settings-page/organization-info.state";
import { UIPreferencesState } from "./main/shared/state/preferences/ui-preferences.state";
import { LegalDocumentsState } from "./main/organization-settings-page/legal-documents/legal-documents.state";
import { OpportunitySettingsState } from "./main/organization-settings-page/opportunity-settings-page/opportunity-settings.state";
import { EmailVerificationPageState } from "./account/email-verification-page/email-verification-page.state";
import { ExternshipOpportunityState } from "./main/my-opportunities/my-opportunities-setup/externship-opportunity/externship-opportunity.state";
import { MyOpportunityState } from "./main/my-opportunities/my-opportunities.state";
import { ClinicLocationsState } from "./main/organization-settings-page/locations-page/clinic-locations.state";
import { OpportunitiesListingState } from "./main/opportunities-listing/opportunities-listing.state";
import { MyStudentsState } from "./main/my-students/my-students.state";
import { OperationalDetailsState } from "./main/organization-settings-page/operational-details/operational-details.state";
import { StaticDataState } from "./main/shared/state/static-data.state";
import { ApplicationState } from "./main/application/application.state";
import { ClinicProfileState } from "./main/clinic-profile/clinic-profile.state";
import { ApplicationsState } from "./main/applications/applications.state";
import { PlacementState } from "./main/placement/placement.state";
import { SchoolProfileState } from "./main/school-profile/school-profile.state";
import { OrganizationUsersState } from "./main/organization-settings-page/users-page/organization-users.state";
import { ProfileState } from "@platform-app/app/main/user-profile/profile.state";
import { OnboardingState } from "@platform-app/app/main/dashboard-page/onboarding.state";

const states = [
  AuthState,
  SignUpState,
  ClinicsState,
  OrganizationInfoState,
  OnboardingState,
  UIPreferencesState,
  LegalDocumentsState,
  OpportunitySettingsState,
  EmailVerificationPageState,
  ExternshipOpportunityState,
  MyOpportunityState,
  ClinicLocationsState,
  OpportunitiesListingState,
  MyStudentsState,
  ProfileState,
  OperationalDetailsState,
  StaticDataState,
  ClinicProfileState,
  SchoolProfileState,
  ApplicationsState,
  PlacementState,
  OrganizationUsersState,
  ApplicationState,
];

export const provideNgxs = () =>
  importProvidersFrom(
    NgxsModule.forRoot(states),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !environment.enableNgxsDebugging,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.enableNgxsDebugging,
    }),
    NgxsStoragePluginModule.forRoot({
      keys: [
        {
          key: AuthState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: UIPreferencesState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: StaticDataState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: EmailVerificationPageState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: MyOpportunityState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: OpportunitiesListingState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: ApplicationState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: OrganizationInfoState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: OnboardingState,
          engine: SESSION_STORAGE_ENGINE,
        },
      ],
    }),
  );
